// .contact-heading {
//   font-size: 36px;
//   font-weight: 700;
//   color: #054d99;
//   margin-left: 142px;
// }
// .contact-des {
//   margin-left: 142px;
// }
// // @media screen and (max-width: 991px) {
// //   .contact-heading {
// //     // margin-left: 18px;
// //     margin: 0 0 0 10px !important;
// //     font-size: 30px !important;
// //     font-weight: 600 !important;
// //   }
// //   .contact-des {
// //     margin: 30px 0 0 22px !important;
// //   }
// //   .img-fluid {
// //     margin: 50px 0 0 22px !important;
// //   }
// // }

// @media screen and (max-width: 992px) {
//   .contact-heading {
//     // margin-left: 18px;
//     margin: 0 0 0 22px !important;
//     font-size: 30px !important;
//     font-weight: 600 !important;
//   }
//   .contact-des {
//     margin: 30px 0 0 22px !important;
//   }
//   .img-fluid {
//     margin: 50px 0 0 22px !important;
//   }
//   .container-main {
//     margin-bottom: -36px !important;
//   }
// }

// @media screen and (max-width: "767px") {
//   .container-main {
//     margin-bottom: 36px !important;
//   }
// }

// .contact-us {
//   z-index: 999;
//   width: 55% !important;
//   border-radius: 10px;
//   // margin-top: 6%;
//   background: #0d61bb;
// }
// @media screen and (max-width: 1570px) {
//   .contact-us {
//     z-index: 999;
//     width: 55% !important;
//     // width: 900px;
//     border-radius: 10px;
//     margin-top: 6%;
//     background: #0d61bb;
//   }
// }
// @media screen and (max-width: 1451px) {
//   .contact-us {
//     width: 55% !important;
//     // width: 880px;
//     margin-top: 10px;
//   }
// }
// @media screen and (max-width: 1381px) {
//   .contact-us {
//     width: 800px;
//   }
// }
// @media screen and (max-width: 1102px) {
//   .contact-us {
//     width: 55% !important;
//     margin-top: -28px;
//   }
// }
// @media screen and (max-width: 770px) {
//   .contact-us {
//     width: 55% !important;
//     margin-top: 400px !important;
//   }
// }
// @media screen and (max-width: 697px) {
//   .contact-us {
//     width: 75% !important;
//     margin-top: 400px !important;
//   }
// }
// @media screen and (max-width: 575px) {
//   .contact-us {
//     margin-top: 400px !important;
//   }
// }
// @media screen and (max-width: 545px) {
//   .contact-us {
//     margin-top: 400px !important;
//   }
// }
// @media screen and (max-width: 500px) {
//   .contact-us {
//     margin-top: 380px !important;
//   }
// }
// @media screen and (max-width: 465px) {
//   .contact-us {
//     margin-top: 360px !important;
//   }
// }
// @media screen and (max-width: 420px) {
//   .contact-us {
//     margin-top: 340px !important;
//   }
// }
// @media screen and (max-width: 386px) {
//   .contact-us {
//     margin-top: 310px !important;
//   }
// }

.contact-heading{
  font-size: 36px;
  font-weight: 700;
  color:  #054D99;
  margin-left: 142px;
}

.contact-des{
  margin-left: 142px;
  margin-right: 15px;
}

@media screen and (max-width: 991px) {
  .contact-heading {
    // margin-left: 18px;
    margin: 0 0 0 22px !important;
    font-size: 30px !important;
    font-weight: 600 !important;
  }
  .contact-des {
    margin: 30px 0 0 22px !important;
  }
  .img-fluid {
    margin: 50px 0 0 22px !important;
  }
  .container-main {
    margin-bottom: -36px !important;
  }
}
@media screen and (max-width: "767px") {
  .container-main {
    margin-bottom: 36px !important;
  }
}

.Contactus {
  z-index: 999;
  width: 55% !important;
  border-radius: 10px;
  // margin-top: 6%;
  background: #0d61bb;
}
@media screen and (max-width: 1570px) {
  
  .Contactus {
    z-index: 999;
    width: 55% !important;
    // width: 900px;
    border-radius: 10px;
    margin-top: 6%;
    background: #0d61bb;
  }
}
@media screen and (max-width: 1451px) {
 
  .Contactus {
    width: 55% !important;
    // width: 880px;
  }
}
@media screen and (max-width: 1381px) {
 
  .Contactus {
    width: 800px;
  }
}
@media screen and (max-width: 1102px) {

  .Contactus {
    width: 55% !important;
  }
  
}
@media screen and (max-width: 770px) {

  .Contactus{
    width: 55% !important;
    margin-top: 400px !important;
  }
 
}
@media screen and (max-width: 697px) {
  .Contactus {
    width: 75% !important;
    margin-top: 400px !important;
  }
}
@media screen and (max-width: 575px) {
  .Contactus {
    margin-top: 400px !important;
  }
}
@media screen and (max-width: 545px) {
  .Contactus {
    margin-top: 400px !important;
  }
}
@media screen and (max-width: 500px) {
  .Contactus{
    margin-top: 380px !important;
  }
}
@media screen and (max-width: 465px) {
  .Contactus {
    margin-top: 360px !important;
  }
}
@media screen and (max-width: 420px) {
  .Contactus {
    margin-top: 340px !important;
  }
}
@media screen and (max-width: 386px) {
  .Contactus{
    margin-top: 310px !important;
  }
}