.add_new_size {
  margin-top: 10px;
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-left: 10px;
  margin-bottom: 16px;
}