.countdown-text {
	display: flex;
	align-items: center;
	justify-content: space-between;

	> p {
		font-size: 0.8rem;
		font-weight: 500;
		color: #637381;
	}

	> button {
		background-color: #fff;
		border: none;
		color: #FF5630;
		font-weight: 500;
		text-decoration: underline;
		cursor: pointer;
	}
}

.submit-btn {
	width: 100%;
	background-color: #3366FF;
	border: none;
	color: #fff;
	border-radius: 0.3rem;
	font-size: 1rem;
	font-weight: 500;
	padding: 0.5rem;
}
