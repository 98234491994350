@media screen and (max-width: 770px) {
  .set-size {
    width: 100% !important;
  }
}

@media screen and (max-width: 695px) {
  .set-size {
    width: 100% !important;
  }
}
@media screen and (max-width: 1024px) {

.set-size{
  margin-top: 9px;
}}


.margin-content {
  margin-left: 90px;
}
.login_text_ankr {
  font-family: "Inter";
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -5px;
  // color: #115CAC;
  font-size: 16px;
  background-color: white;
  color: #115cac;
  border: 2px solid white;
  border-radius: 4px;
}
.backgroundd {
  background-color: transparent !important;
  width: 85px;
  height: 35px;
  border-radius: 4px;
  @media screen and (min-width: 992px) {
    background-color: #fff;
  }
}
.background-nav {
  width: 85px;
  height: 35px;
  border-radius: 4px;
  @media (min-width: 992px) {
    background-color: #fff !important;
  }
}

.colorText {
  color: white;
  @media (min-width: 992px) {
    color: #115cac;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -3px;
  }
}

.landing_banner_main_wrapper {
  width: 100%;
  padding-top: 0px;
  background-color: #0d61bb;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(13, 97, 187, 0.97) 0%,
    #054d99 100%
  );
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
}

#landing-page-main-heading {
  margin: 70px 0px;
}

.landing_banner_inner_wrapper {
  width: 100%;
  height: 774px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.content_landing_wrapper_main {
  width: 100%;
  display: flex;
  justify-content: center;
}

.content_landing_wrapper {
  display: flex;
  justify-content: space-between;
  max-width: 1300px;
  padding: 0px 50px;
  margin: 50px;
}

.content_landing_wrapper div h3 {
  font-size: 60px;
  font-family: "Inter";
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: 0px 6px 4px rgba(0, 0, 0, 0.27);
  width: 75%;
}
.image_banner_class img {
  margin-left: -190px;
  width: 539.997px;
  height: 600px;
}

.content-font-p {
  color: #fff;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 165.523%;
  width: 59%;
}
.smallscreen {
  display: none !important;
}

.subscribe-section {
  margin-bottom: 15px;
}
.directionn {
  flex-direction: column;
  margin-top: 45px;
}
.landing_banner_button {
  text-decoration: none;
  color: white;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  height: 56px;
  border-radius: 4px;
  border: 2px solid #fff;
  background: rgba(255, 255, 255, 0);
}
.how-it-worked {
  z-index: 999;
  width: 55% !important;
  border-radius: 10px;
  // margin-top: 6%;
  background: #0d61bb;
}
.cont-heading-email {
  margin-top: -3px;
  color: #ffffff;
  font-weight: 700;
  font-size: 30px;
  font-family: "Inter";
  text-shadow: 0px 6px 4px rgba(0, 0, 0, 0.08);
  line-height: normal;
}
@media screen and (max-width: 1570px) {
  .content_landing_wrapper div h3 {
    font-size: 48px;
  }
  .image_banner_class img {
    margin-left: -190px;
    width: 490px;
    height: 600px;
  }
  .how-it-worked {
    z-index: 999;
    width: 55% !important;
    // width: 900px;
    border-radius: 10px;
    margin-top: 6%;
    background: #0d61bb;
  }
}

@media screen and (max-width: 1508px) {
  .content_landing_wrapper div h3 {
    font-size: 48px;
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: 0px 6px 4px rgba(0, 0, 0, 0.27);
    width: 90%;
  }
}

@media screen and (max-width: 1451px) {
  .content-font-p {
    width: 50%;
    font-size: 18px;
  }
  .content_landing_wrapper div h3 {
    font-size: 48px;
  }
  .how-it-worked {
    width: 55% !important;
    // width: 880px;
  }
}

@media screen and (max-width: 1381px) {
  .content-font-p {
    width: 55%;
  }
  .landing_banner_button {
    font-size: 18px;
    width: 200px;
    height: 46px;
  }
  .how-it-worked {
    width: 800px;
  }
  .cont-heading-email {
    margin-top: -16px;
    font-size: 30px;
  }
}

@media screen and (max-width: 1306px) {
  .content-font-p {
    width: 60%;
    font-size: 16px;
  }
}

.darkGray {
  color: #404a50;
}

.darkBlue {
  color: #1468ba;
}

.content_image_wrapper {
  padding: 5%;
  padding-bottom: 5%;
}

.btn_landing_wrapper {
  margin-top: 50px;
}

.contanct-us-heading {
  padding-left: 50px;
  margin-top: 14px;
}
@media screen and (max-width: 1326px) {
  .landing_banner_inner_wrapper {
    height: 700px;
  }
  .image_banner_class img {
    // margin-left: -92px !important;
    width: 450px !important;
    height: 550px !important;
  }
  .content_landing_wrapper div h3 {
    font-size: 38px;
    font-family: "Inter";
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: 0px 6px 4px rgba(0, 0, 0, 0.27);
    width: 100%;
  }

  #landing-page-main-heading {
    width: 700px;
  }
}

@media screen and (max-width: 992px) {
  .landing_banner_button {
    font-size: 16px;
    width: 190px;
    // height: 74px;
  }
  .margin-content {
    margin-left: 24px;
  }
}
@media screen and (max-width: 990px) {
  .margin-content {
    margin-left: 0px;
  }
  .contanct-us-heading {
    padding-left: 0px;
    margin-top: 14px;
  }
  .address-info {
    display: flex;
    margin-left: 44px !important;
  }
  .subscribe-section {
    margin-bottom: 4px;
  }
  .directionn {
    margin-top: 35px !important;
  }
}
@media screen and (max-width: 572px) {
  .landing_banner_button {
    font-size: 16px;
    width: 180px;
    height: 50px;
  }
  .margin-content {
    margin-left: 0px;
  }
}

.landing_button {
  color: #ffffff;
  background-color: #115cac;
  text-decoration: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  border: none;
  width: 156px;
  height: 56px;
}

@media screen and (max-width: 310px) {
  .landing_button {
    font-size: 15px;
  }
  .margin-content {
    margin-left: 0px;
  }
}

.content_section_head {
  color: #0d61bb;
  text-shadow: 0px 6px 4px rgba(0, 0, 0, 0.08);
  font-family: "Inter";
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 80%;
}

.content_section_desc {
  color: #484848;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 139.023%;
  width: 80%;
}

.vendor-image-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.google-cloud {
  width: 380px;
  height: 58px;
  background: #f7f7f7;
  margin-top: 47px;
}

.microsoft-azure {
  width: 270px;
  height: 152px;
  flex-shrink: 0;
  background: #f7f7f7;
}

.amazon {
  width: 236px;
  height: 89px;
  flex-shrink: 0;
  background: #f7f7f7;
  margin-top: 29px;
}

@media screen and (max-width: 1370px) {
  .content_section_head {
    color: #0d61bb;
    text-shadow: 0px 6px 4px rgba(0, 0, 0, 0.08);
    font-family: "Inter";
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 90%;
  }
  .image_banner_class img {
    margin-left: -190px;
    width: 470px;
    height: 600px;
  }
}

@media screen and (max-width: 1270px) {
  .content_landing_wrapper div h3 {
    font-size: 38px;
    font-family: "Inter";
  }

  .content_section_desc {
    color: #484848;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 139.023%;
    width: 90%;
  }

  .image_banner_class img {
    margin-left: -169px !important;
    width: 419px !important;
    height: 538px !important;
  }
}

@media screen and (max-width: 1153px) {
  .content_landing_wrapper div h3 {
    font-size: 32px;
  }

  .content_landing_wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 1300px;
    padding: 70px 190px;
    margin: 50px;
  }

  .image_banner_class img {
    margin-left: -180px !important;
    width: 400px !important;
    height: 488px !important;
    // margin-top: 20px;
  }
}

@media screen and (max-width: 1095px) {
  .content_landing_wrapper div h3 {
    font-size: 32px;
  }

  .content-font-p {
    width: 70%;
    font-size: 16px;
  }
}

@media screen and (max-width: 992px) {
  .content_landing_wrapper div h3 {
    font-size: 28px;
  }

  .content-font-p {
    width: 80%;
    font-size: 14px;
  }

  #landing-page-main-heading {
    width: 500px;
  }

  .image_banner_class img {
    margin-left: -106px !important;
    width: 350px !important;
    height: 420px !important;
    margin-top: 13px;
  }
}

@media screen and (max-width: 950px) {
  .content_landing_wrapper {
    padding: 20px;
    padding-left: 60px;
  }
}

@media screen and (max-width: 905px) {
  .content_landing_wrapper div h3 {
    font-size: 25px;
  }

  .image_banner_class img {
    margin-left: -150px !important;
    width: 320px !important;
    height: 400px !important;
    margin-top: 38px;
  }
  .landing_banner_inner_wrapper {
    height: 600px !important;
  }

  #landing-page-main-heading {
    width: 300px;
  }

  .content_landing_wrapper {
    padding: 20px;
    padding-left: 61px;
    width: 800px;
  }
}

@media screen and (max-width: 816px) {
  // .content_landing_wrapper div h3 {
  //   font-size: 30px;
  // }

  .image_banner_class img {
    margin-left: -180px;
    width: 316px;
    height: 400px;
  }
}

.text---white {
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text--white {
  color: #fff;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (max-width: 980px) {
  .text---white {
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .text--white {
    color: #fff;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .landing_banner_inner_wrapper {
    height: 674px;
  }
}

@media screen and (max-width: 800px) {
  .content_landing_wrapper div h3 {
    font-size: 25px;
    font-family: "Inter";
  }

  .image_banner_class img {
    margin-left: -180px !important;
    width: 280px !important;
    height: 400px !important;
  }
}

@media screen and (max-width: 775px) {
  .content_landing_wrapper div h3 {
    font-size: 28px;
  }
  .btn_landing_wrapper {
    margin-top: 40px;
  }
  .content-font-p {
    width: 100%;
    font-size: 13px;
  }

  .image_banner_class img {
    width: 280px !important;
    height: 370px !important;
    margin-top: 45px;
  }
  .landing_banner_inner_wrapper {
    height: 574px !important;
  }
  .landing_banner_button {
    font-size: 16px;
    width: 180px;
    height: 45px;
  }
}

@media screen and (max-width: 765px) {
  .image_banner_class img {
    width: 300px;
    height: 400px;
  }
}

@media screen and (max-width: 753px) {
  .image_banner_class img {
    width: 300px;
    height: 400px;
  }
}

@media screen and (max-width: 743px) {
  .image_banner_class img {
    width: 300px;
    height: 400px;
  }

  .content_landing_wrapper {
    padding: 10px;
    padding-left: 12px;
    width: 1000px;
  }
}

@media screen and (max-width: 723px) {
  .image_banner_class img {
    width: 300px;
    height: 400px;
  }
}

@media screen and (max-width: 712px) {
  .image_banner_class img {
    width: 300px;
    height: 400px;
  }
}

@media screen and (max-width: 694px) {
  .image_banner_class img {
    width: 250px !important;
    height: 350px !important;
  }
}

@media screen and (max-width: 683px) {
  //   font-size: 30px;
  // }

  .content_landing_wrapper {
    padding: 10px;
    padding-left: 12px;
    width: 1000px;
  }
  .landing_banner_button {
    font-size: 12px;
    width: 150px;
    height: 35px;
  }
}

@media screen and (max-width: 655px) {
  .content_landing_wrapper div h3 {
    font-size: 26px;
  }

  .image_banner_class img {
    width: 220px !important;
    height: 320px !important;
    margin-top: 39px;
  }
}

@media screen and (max-width: 623px) {
  .image_banner_class img {
    width: 250px;
    height: 350px;
    margin-top: 28px;
  }
  .landing_banner_inner_wrapper {
    height: 500px !important;
  }
}

@media screen and (max-width: 606px) {
  .content_landing_wrapper div h3 {
    font-size: 25px;
  }
}

@media screen and (max-width: 595px) {
  .content_landing_wrapper div h3 {
    font-size: 22px;
  }

  .image_banner_class img {
    width: 180px !important;
    height: 290px !important;
    // margin-top: 65px;
  }
}

@media screen and (max-width: 575px) {
  .content_landing_wrapper div h3 {
    font-size: 16px;
  }
  .vendor-image-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .google-cloud {
    margin-top: 32px;
  }
  .amazon {
    margin-top: -5px;
    margin-bottom: 22px;
  }
}

@media screen and (max-width: 560px) {
  .content_landing_wrapper {
    padding: 0px;
    margin-top: 40px;
  }

  .content_landing_wrapper div h3 {
    font-size: 22px;
    font-family: "Inter";
  }

  .content-font-p {
    width: 100%;
    font-size: 14px;
  }

  .content_landing_wrapper_main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 450px;
  }

  .landing_banner_inner_wrapper {
    width: 100%;
    height: 400px !important;
  }

  .content_image_wrapper {
    padding-right: 0px;
    display: none;
    width: 200%;
    /*background-color: red;*/
  }

  .btn_landing_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }

  .landing_banner_button {
    margin-left: -25px;
  }
}

.navbarColor {
  background: #0d61bb;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
}

.text-white {
  color: white !important;
}

.paddingnav {
  padding-right: 50px;
}

.Input-Box {
  border: 2px;
  border-radius: 16px;
  margin-top: 4%;
  background-color: #ffffff;
  width: 53%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}

.boxShadow {
  box-shadow: -1px 0px 39px 3px rgba(0, 0, 0, 0.1);
}

.choose-file-background {
  background: rgb(248, 249, 251);
  // height:20vh;
  height: 270px;
  margin-top: 11%;
}

.partner-available {
  color: #131313;
  text-align: center;
  text-shadow: 0px 6px 4px rgba(0, 0, 0, 0.08);
  font-family: "Inter";
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.navright {
  margin-right: 30px;
}

@media screen and (max-width: 1345px) {
  .choose-file-background {
    // background: #0d61bb;
    // height:30vh;
    height: 275px;
    margin-top: 11%;
  }

  .partner-available {
    font-size: 38px;
  }
}

@media screen and (max-width: 1205px) {
  .Input-Box {
    width: 60%;
  }
}

@media screen and (max-width: 1045px) {
  .choose-file-background {
    // background: #0d61bb;
    // height:30vh;
    height: 250px;
    margin-top: 13%;
  }
}

@media screen and (max-width: 855px) {
  .choose-file-background {
    height: 250px;
    margin-top: 15%;
  }
}

.button-color {
  background-color: #0d61bb;
}

.justify-Cont {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-pa {
  // padding: 9px;
  height: 55px;
  width: 280px;
  border-radius: 8px;
}

.text--color {
  color: #8e8b8b;
  font-family: "Inter";
  font-size: 22px;
}

.directionn {
  flex-direction: column;
  margin-top: 45px;
}

.content-para {
  color: #596470;
  text-align: center;
  text-shadow: 0px 6px 4px rgba(0, 0, 0, 0);
  font-family: "Inter";
  font-size: 29px;
  font-style: normal;
  font-weight: 500;
  width: 60%;
  line-height: 131.023%;
}

.TopStyling {
  margin-top: 80px;
}

.content-para-config {
  color: #596470;
  text-align: center;
  text-shadow: 0px 6px 4px rgba(0, 0, 0, 0);
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 131.023%;
  width: 630px;
}
@media screen and (max-width: 1430px) {
  .second-contant {
    padding-left: 80px;
    margin-top: 1%;
  }
}
.second-contant {
  padding-left: 10px;
  margin-top: 1%;
}

.content-paraa {
  width: 38%;
  color: #000;
  text-align: center;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 155.523%;
}
@media screen and (max-width: 2365px) {
  .content-paraa {
    width: 42%;
  }
}

@media screen and (max-width: 2065px) {
  .content-paraa {
    width: 46%;
  }
}
@media screen and (max-width: 1982px) {
  .content-paraa {
    width: 48%;
  }
}
@media screen and (max-width: 1900px) {
  .content-paraa {
    width: 50%;
  }
}
@media screen and (max-width: 1824px) {
  .content-paraa {
    width: 52%;
  }
}

@media screen and (max-width: 1750px) {
  .content-paraa {
    width: 55%;
  }
}
@media screen and (max-width: 1657px) {
  .content-paraa {
    width: 59%;
  }
}
@media screen and (max-width: 1615px) {
  .content-paraa {
    width: 61%;
  }
}
@media screen and (max-width: 1561px) {
  .content-paraa {
    width: 63%;
  }
}
@media screen and (max-width: 1516px) {
  .content-paraa {
    width: 65%;
  }
}

@media screen and (max-width: 1468px) {
  .content-paraa {
    width: 67%;
  }
}
@media screen and (max-width: 1426px) {
  .content-paraa {
    width: 69%;
  }
}
@media screen and (max-width: 1380px) {
  .content-paraa {
    width: 71%;
  }
}

.custom-footer-content {
  height: 175px;
  color: #fff;
  margin-top: -150px;
}
.contact {
  font-family: "Inter";
  font-weight: 700;
  size: 18px;
  line-height: 26.1px;
  color: rgba(153, 153, 153, 1);
}
.footer-content {
  color: #fff;
  height: 105px;

  font-family: "Inter";
  margin-top: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23.2px;
}

.dropdown-toggle::after {
  margin-left: 1.255em !important;
}
.footer-content2 {
  color: #fff;
  height: 105px;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23.2px;
  margin-top: 4%;
  margin-bottom: 0%;
}
.language-selector {
  background-color: #343434; /* Your desired background color */
  color: white; /* Your desired text color */
  border: 1px solid #343434; /* Your desired border properties */
  width: 145px; /* Adjust the width as necessary */
  /* Add other styles as needed */
}

.language-selector .dropdown-menu {
  background-color: white; /* The color you want for the dropdown */
  /* Add other styles for the dropdown menu */
}
.language-selector {
  background-color: #343434; /* Your desired background color */
  color: white; /* Your desired text color */
  border: 1px solid #343434; /* Your desired border properties */
  -webkit-appearance: none; /* Removes default styling on iOS */
  -moz-appearance: none; /* Removes default styling on Firefox */
  appearance: none; /* Removes default styling */
  background-image: url("https://res.cloudinary.com/dstnwi5iq/image/upload/v1706792393/icons8-expand-arrow-24_tqvyvf.png"); /* Path to a custom dropdown icon */
  background-repeat: no-repeat;
  background-position: right 10px center; /* Adjust as needed */
  background-size: 12px; /* Adjust as needed */
  width: 145px; /* Adjust the width as necessary */
  /* Add other styles as needed */
}

.language-selector::-ms-expand {
  display: none; /* Hide the default dropdown arrow in IE11 */
}
.contactUs {
  width: 22rem;
  margin-top: 32px;
  margin-left: 32px;
}
.followus {
  width: 22rem;
  margin-top: 27px;
}
@media screen and (max-width: 775px) {
  .partner-available {
    font-size: 29px;
  }

  .custom-footer-content {
    height: 15px;
  }
  .second-contant {
    padding-left: 2%;
    margin-top: 1%;
  }
  .footer-content {
    width: 100%;
  }
  .footer-content2 {
    width: 100%;
  }
}

@media (max-width: 1020px) {
  .footer-logo {
    // width: 70% !important;
    height: auto;
  }
}

@media (max-width: 768px) {
  .contanct-us-heading{
    margin-top: 40px !important;
  }
  .follow{
    margin-top: 40px !important;
  }  
  .footer-content2{
    margin-top: -40px !important;
  }
  .second-content{
    width: 50%;
  }
  .second-contant{
    width: 50%;
  }
  .footer-logo {
    width: 50% !important;
    height: auto;
  }

  .contanct-us-heading {
    padding-left: 0px;
    margin-top: 35px !important;
  }
}
@media screen and (max-width: 767px) {
  .footer-content2 {
    margin-top: 0px !important;
  }
}
@media screen and (max-width: 919px) {
  .footer-content2 {
    padding-top: 25%;
  }
}
@media screen and (max-width: 415px) {
  .custom-footer-content {
    font-size: 20px;
    height: 55px;
  }
  .second-contant {
    padding-left: 0%;
    margin-top: 1%;
  }
  .responsive-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
  }

  .content_landing_wrapper {
    padding: 0px;
    padding-left: 12px;
    width: 300px;
  }

  #landing-page-main-heading {
    width: 60vw;
    height: fit-content;
  }
}
@media screen and (max-width: 1200px) {
  .email-add{
    padding-left: 50px;
  }
  .footer-content2 {
    padding-top: 18%;
  }
  .footer-logo {
    width: 90%;
    height: auto;
  }
  .contactUs {
    width: 17rem;
  }
}
@media screen and (max-width: 920px) {
  .footer-content2 {
    margin-top: 5%;
    padding-top: 0px;
  }
}
@media screen and (max-width: 919px) {
  .lang {
    display: flex;
    justify-content: center;
  }
  .followus {
    display: flex;
    justify-content: center;
    width: 100% !important;
  }
  .contactUs {
    width: 100% !important;
  }
  .footer-content2 {
    margin-bottom: -10%;
  }
}

.content-par {
  color: #343434;
  text-shadow: 0px 6px 4px rgba(0, 0, 0, 0);
  font-family: "Inter";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media screen and (max-width: 1340px) {
  .content-para {
    width: 50%;
    font-size: 18px;
    text-align: center;
  }

  .content-paraa {
    width: 73%;
    font-size: 18px;
  }

  .content-para-config {
    font-family: "Inter";
    font-size: 16px;
    width: 630px;
  }
}
@media screen and (max-width: 1306px) {
  .content-paraa {
    width: 76%;
  }
}
@media screen and (max-width: 1253px) {
  .content-paraa {
    width: 78%;
  }
}
@media screen and (max-width: 1177px) {
  .content-paraa {
    width: 82%;
  }
}
@media screen and (max-width: 1111px) {
  .content-paraa {
    width: 86%;
  }
}

@media screen and (max-width: 1040px) {
  .content-para {
    width: 66%;
    font-size: 16px;
    text-align: center;
  }

  .content-paraa {
    width: 66%;
    font-size: 18px;
    text-align: center;
  }

  .content-para-config {
    font-family: "Inter";
    font-size: 16px;
    width: 60%;
  }
}

@media screen and (max-width: 700px) {
  .Input-Box {
    border: 2px;
    border-radius: 16px;
    background-color: #ffffff;
    width: 90%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  }
  .content-par {
    font-size: 29px;
  }
  .choose-file-background {
    height: 260px;
    margin-top: 17%;
  }
}

@media screen and (max-width: 653px) {
  .choose-file-background {
    height: 260px;
    margin-top: 18%;
  }
}

@media screen and (max-width: 549px) {
  .choose-file-background {
    height: 0vh;
    margin-bottom: 150px;
  }

  .content-par {
    font-size: 15px;
  }
}

@media screen and (max-width: 410px) {
  .choose-file-background {
    height: 0vh;
    margin-bottom: 180px;
  }

  .content-par {
    font-size: 15px;
  }
  .footer-logo {
    width: 70% !important;
  }
}

@media screen and (max-width: 562px) {
  .Input-Box {
    border: 2px;
    border-radius: 16px;
    // height:40vh;
    margin-top: 3%;
    background-color: #ffffff;
    width: 95%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  }

  .button-pa {
    // padding: 10px;
    height: 55px;
    border-radius: 8px;
  }

  .text--color {
    color: #8e8b8b;
    font-family: "Inter";
    font-size: 16px;
  }
}

/*Configure page styling*/
.Digital_record_content {
  text-align: left;
  margin-right: 20px;
  // margin-left: 20px;
}

.inner_section_wrapper_ride {
  padding-top: 5%;
  padding-bottom: 5%;
}

.reverse_ride_flex {
  display: flex;
  justify-content: space-around;
  // align-items: center;
  flex-direction: row-reverse;
}

.content_wrapper_ride {
  padding-top: 10px;
  position: relative;
  width: 50%;
}

.reverse_chex_image_wrapper {
  position: absolute;
  top: -100%;
  right: 0%;
  padding-left: 30%;
}

.straign_content_image_wrapper {
  position: absolute;
  top: -50%;
  left: -10%;
}

.chex_content_image_wrapper img {
  width: 70%;
}

.content_section {
}

.reverse_content {
  padding-right: 10%;
}

.content_section h2 {
  font-family: "Inter";
  font-size: 48px;
  color: #0d61bb;
  font-weight: 700;
}

.content_section p {
  font-family: "Inter";
  font-size: 22px;
  color: #484848;
  padding-right: 12%;
}

.Image_wrapper_ride img {
  height: 316px;
  // width: 90%;
  width: 547px;
  padding-left: 30px;
  // margin-left: 5px;
}
@media screen and (max-width: 1400px) {
  .footer-content2 {
    margin-top: 40px;
  }
}
@media screen and (max-width: 1382px) {
  // .Image_wrapper_ride img {
  //     height: 320px;
  // }
}

@media screen and (max-width: 1367px) {
  // .Image_wrapper_ride img {
  //     height: 350px;
  // }
}

@media screen and (max-width: 1350px) {
  // .Image_wrapper_ride img {
  //     height: 380px;
  // }
}

@media screen and (max-width: 1220px) {
  .content_section_head {
    width: 100%;
  }
}

@media screen and (max-width: 281px) {
  .content_section_head {
    font-size: 14px;
  }

  .content-par {
    font-size: 15px;
    position: relative;
    left: 50px;
  }
}

@media screen and (max-width: 1134px) {
  .logo-align {
    justify-content: flex-start !important;
  }
  .content_section h2 {
    font-family: "Inter";
    font-size: 42px;
    color: #0d61bb;
  }

  .content_section_head {
    font-size: 42px;
  }

  .Image_wrapper_ride img {
    height: 316px;
    width: 520px;
  }

  // .content_section_desc {
  //     font-size: 24px;

  // }
}

@media screen and (max-width: 1105px) {
  .content_section_head {
    font-size: 40px;
  }

  // .content_section_desc {
  //     font-size: 24px;

  // }
}

@media screen and (max-width: 1068px) {
  .content_section h2 {
    font-family: "Inter";
    font-size: 38px;
    color: #0d61bb;
  }

  .content_section_head {
    font-size: 38px;
  }
}

@media screen and (max-width: 1014px) {
  .content_section h2 {
    font-family: "Inter";
    font-size: 36px;
    color: #0d61bb;
  }

  .content_section_head {
    font-size: 36px;
  }

  .content_section_desc {
    font-size: 14px;
  }

  .content_section p {
    font-family: "Inter";
    font-size: 16px;
    color: #484848;
  }

  .reverse_content {
    padding-right: 10%;
  }

  .reverse_chex_image_wrapper {
    position: absolute;
    top: -50%;
    right: 0%;
    padding-left: 30%;
  }

  .straign_content_image_wrapper {
    position: absolute;
    top: -50%;
    left: -10%;
  }

  .chex_content_image_wrapper img {
    width: 60%;
  }
}
@media screen and (max-width: 998px) {
  .followus {
    width: 10rem;
  }
  .contactUs {
    width: 14rem;
    margin-left: 8px;
  }
}

@media screen and (max-width: 984px) {
  .content_section_head {
    font-size: 32px;
  }
  .content-paraa {
    width: 86%;
  }

  //  .contactUs {
  //   width: 15rem;
  //   margin-left: 32px;
  // }
  .followus {
    width: 10rem;
  }
  .lang-cont {
    padding-left: 135px !important;
  }
}

@media screen and (max-width: 930px) {
  .content_section_head {
    font-size: 30px;
  }
}

@media screen and (max-width: 915px) {
  .content_section h2 {
    font-family: "Inter";
    font-size: 29px;
    color: #0d61bb;
  }

  .content_section_head {
    font-size: 29px;
  }

  .content_section p {
    font-family: "Inter";
    font-size: 14px;
    color: #484848;
  }

  .reverse_content {
  }

  .reverse_chex_image_wrapper {
    position: absolute;
    top: -50%;
    left: -20%;
    padding-left: 30%;
  }
}

@media screen and (max-width: 893px) {
  .content_section_head {
    font-size: 28px;
  }
}

@media screen and (max-width: 884px) {
  .content_section_head {
    font-size: 26px;
  }
}

@media screen and (max-width: 815px) {
  .content_section_head {
    font-size: 20px;
  }
}

@media screen and (max-width: 757px) {
  .reverse_ride_flex {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
  }

  .content_section h2 {
    font-family: "Inter";
    font-size: 34px;
    color: #0d61bb;
  }

  .content_section_head {
    font-size: 34px;
  }

  .content_section_desc {
    font-size: 20px;
  }

  .content_section p {
    font-family: "Inter";
    font-size: 18px;
    color: #484848;
    padding-right: 0%;
  }

  .reverse_content {
    padding-left: 0%;
    padding-right: 0%;
  }

  .reverse_chex_image_wrapper {
    position: absolute;
    display: none;
    top: -100%;
    left: 0%;
    padding-left: 30%;
  }

  .Image_wrapper_ride img {
    // width: 100%;
    width: 547px;
    padding: 20%;
    height: 417px;
  }

  .chex_Img_wrapper img {
    width: 120%;
    padding: 10%;
  }
}

@media screen and (max-width: 480px) {
  .partner-available {
    font-size: 20px;
  }

  .reverse_chex_image_wrapper {
    position: absolute;
    top: -50%;
    left: 0%;
    padding-left: 10%;
  }

  .straign_content_image_wrapper {
    position: absolute;
    top: -10%;
    left: 0%;
  }

  .chex_content_image_wrapper img {
    width: 70%;
  }

  .Image_wrapper_ride img {
    width: 100%;
    padding: 10%;
  }

  .chex_Img_wrapper img {
    width: 100%;
    padding: 5%;
  }

  .content_section h2 {
    font-family: "Inter";
    font-size: 26px;
    color: #0d61bb;
  }

  .content_section p {
    font-family: "Inter";
    font-size: 16px;

    color: #484848;
  }
}

@media screen and (max-width: 350px) {
  .chex_content_image_wrapper img {
    // display: none;
  }

  .content_section h2 {
    font-family: "Inter";
    font-size: 20px;
    color: #0d61bb;
  }

  .content_section p {
    font-family: "Inter";
    font-size: 12px;
    color: #484848;
  }

  .landing_banner_button {
    margin-left: -15px;
    font-size: 14px;
    width: 160px;
    height: 40px;
  }
}
.address-info {
  display: flex;
  margin-left: 18px;
}

/*generatePricing page styling*/
.my-card {
  margin-right: 55px;
  margin-left: 55px;
}

@media screen and (max-width: 1350px) {
  .my-card {
    margin-right: 45px;
    margin-left: 45px;
  }
}

@media screen and (max-width: 999px) {
  .hide-content {
    display: none;
  }
  .contanct-us-heading {
    padding-left: 24px;
    margin-top: 14px;
  }
}

@media screen and (max-width: 993px) {
  .my-card {
    margin-right: 30px;
    margin-left: 30px;
  }
  .cotact-info {
    margin-left: 12px;
    width: 15rem;
  }
  .contanct-us-heading {
    padding-left: 12px;
    margin-top: 14px;
  }
  .address-info {
    display: flex;
    margin-left: 44px !important;
  }
}

@media screen and (max-width: 813px) {
  .my-card {
    margin-right: 10px;
    margin-left: 10px;
  }
}

.cont-heading {
  color: #054d99;
  font-family: "Inter";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.fontSizeapply {
  color: #0d61bb;
  // text-align: center;
  text-shadow: 0px 6px 4px rgba(0, 0, 0, 0);
  font-family: "Inter";
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

// .firstcardpadding{
//   padding-right: 79px;
// }

.equal-height-image-azure1 {
  height: 56px;
}

.equal-height-image-gcp1 {
  height: 70px;
  position: relative;
  left: -50px;
}

.fontSizep {
  color: #343434;
  // text-align: center;
  text-shadow: 0px 6px 4px rgba(0, 0, 0, 0);
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 10px;
}

.card2padding {
  padding-right: 7px;
}

.form-padding {
  padding: 1rem !important;
}

@media screen and (max-width: 1599px) {
  .fontSizeapply {
    font-size: 24px;
  }

  .google-cloud {
    width: 330px;
    height: 48px;
    margin-top: 37px;
  }

  .microsoft-azure {
    width: 220px;
    height: 132px;
  }

  .amazon {
    width: 206px;
    height: 79px;
    margin-top: 25px;
  }
}

@media screen and (max-width: 1500px) {
  .google-cloud {
    width: 300px;
    height: 48px;
    margin-top: 37px;
  }

  .microsoft-azure {
    width: 189px;
    height: 115px;
  }

  .amazon {
    width: 178px;
    height: 68px;
    margin-top: 25px;
  }
}
@media screen and (max-width: 1390px) {
  .fontSizeapply {
    font-size: 24px;
  }
  .equal-height-image-azure1 {
    height: 46px;
  }
  .equal-height-image-gcp1 {
    height: 60px;
  }
  .google-cloud {
    width: 290px;
    height: 48px;
    margin-top: 37px;
  }

  .microsoft-azure {
    width: 190px;
    height: 132px;
  }

  .amazon {
    width: 156px;
    height: 79px;
    margin-top: 25px;
  }
  .form-padding {
    padding: 0.6rem !important;
  }
}
@media screen and (max-width: 1300px) {
  .google-cloud {
    width: 260px;
    height: 38px;
    margin-top: 42px;
  }
  .microsoft-azure {
    width: 160px;
    height: 118px;
  }

  .amazon {
    width: 126px;
    height: 69px;
    margin-top: 25px;
  }
}

@media screen and (max-width: 1200px) {
  .google-cloud {
    width: 250px;
    height: 38px;
    margin-top: 30px;
  }
  .microsoft-azure {
    width: 150px;
    height: 100px;
  }
  .amazon {
    width: 116px;
    height: 69px;
    margin-top: 17px;
  }
}

@media screen and (max-width: 1131px) {
  .fontSizeapply {
    font-size: 23px;
  }
}

@media screen and (max-width: 1098px) {
  .fontSizeapply {
    font-size: 20px;
  }

  .fontSizep {
    font-size: 16px;
  }
  .cont-heading {
    font-size: 30px;
  }
  .content-paraa {
    font-size: 16px;
    width: 80%;
  }

  .equal-height-image {
    height: 112.402px;
    width: 193.616px;
    position: relative;
    top: 140px;
    left: -300px;
  }
}

@media screen and (max-width: 1028px) {
  .fontSizeapply {
    font-size: 18px;
  }
  .content-paraa {
    width: 84%;
  }
}

@media screen and (max-width: 998px) {
  .google-cloud {
    width: 200px;
    height: 30px;
    margin-top: 30px;
  }
  .microsoft-azure {
    width: 100px;
    height: 92px;
  }
  .amazon {
    width: 88px;
    height: 54px;
    margin-top: 17px;
  }
}

@media screen and (max-width: 876px) {
  .fontSizeapply {
    font-size: 16px;
  }

  .fontSizep {
    font-size: 14px;
  }
  .content-paraa {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .google-cloud {
    width: 150px;
    height: 20px;
    margin-top: 22px;
  }
  .microsoft-azure {
    width: 80px;
    height: 61px;
  }
  .amazon {
    width: 82px;
    height: 36px;
    margin-top: 14px;
  }
}

.card-container {
  margin-top: -105px;
}

.how-it-work {
  width: 100%;
  height: 320px !important;
  background: #fff;
}

.margin--lef {
  margin-left: 50%;
}

.widthapply {
  width: 110%;
  margin-left: -12px;
}
.cont-subtitle {
  font-family: "Inter";
  color: white;
  font-weight: 400;
  font-size: 18px;
  line-height: 26.1px;
}
.form-field {
  padding: 14px;
}
.footer-margin-top {
  margin-top: -8% !important;
}
.footerTop {
  margin-top: 12%;
}

@media screen and (max-width: 2500px) {
  .footer-margin-top {
    margin-top: -5% !important;
  }
  .card-group {
    margin-bottom: 80px;
  }
}
@media screen and (max-width: 1700px) {
  .footer-margin-top {
    margin-top: -8% !important;
  }
}
@media screen and (max-width: 1367px) {
  .how-it-work {
    width: 100%;
    height: 320px !important;
  }
}

@media screen and (max-width: 1102px) {
  .cont-heading-email {
    margin-left: 0px;
    font-size: 22px;
  }

  .how-it-worked {
    width: 55% !important;
  }
  .form-padding {
    padding: 0.3rem !important;
  }
}

@media screen and (max-width: 1068px) {
  .how-it-work {
    width: 100%;
    height: 320px !important;
    // background: #0d61bb;
  }
}

@media screen and (max-width: 768px) {
  .how-it-work {
    width: 100%;
    height: 320px !important;
  }
  .footer-margin-top {
    margin-top: -12% !important;
  }
  .footerTop {
    margin-top: 15%;
  }

  .card2padding {
    padding-right: 1px;
  }
  .contactUs {
    margin-top: 11px;
  }
  .cotact-info {
    margin-left: 12px;
    width: 100% !important;
  }
  .address-info {
    display: flex;
    margin-left: 44px !important;
  }
  .form-padding {
    padding: 0.5rem !important;
  }
}

@media screen and (max-width: 489px) {
  .how-it-work {
    width: 100%;
    height: 320px !important;
    // background: #0d61bb;
  }
}

@media screen and (max-width: 1085px) {
  .cont-heading-email {
    font-size: 26px;
    margin-top: -20px;
  }
}

@media screen and (max-width: 939px) {
  .cont-heading-email {
    font-size: 25px;
    margin-top: -13px;
  }
}

@media screen and (max-width: 899px) {
  // .cont-heading-email {
  //   font-size: 25px;
  // }
  .cont-subtitle {
    font-size: 16px;
  }
  .directionn {
    margin-top: 30px !important;
  }
}

@media screen and (max-width: 900px) {
  .cont-heading-email {
    font-size: 25px;
  }
}

@media screen and (max-width: 770px) {
  .cont-heading-email {
    font-size: 23px;
  }

  .how-it-worked {
    width: 55% !important;
  }
  .cont-subtitle {
    font-size: 14px;
  }
}

@media screen and (max-width: 630px) {
  .responsive-br {
    display: none;
  }

  .cont-heading-email {
    font-size: 23px;
  }
}
@media screen and (max-width: 697px) {
  .how-it-worked {
    width: 75% !important;
  }
}

@media screen and (max-width: 639px) {
  .fontSizep {
    font-size: 12px;
  }

  .content-paraa {
    font-size: 12px;
  }
  .cont-heading-email {
    font-size: 25px;
  }
}

@media screen and (max-width: 594px) {
  .cont-heading-email {
    font-size: 21px;
  }
}

@media screen and (max-width: 575px) {
  .largescreen {
    display: none !important;
  }
  .smallscreen {
    display: inline-block !important;
  }

  .my-card {
    margin-top: 20px;
    margin-right: 60px;
    margin-left: 60px;
  }

  .fontSizep {
    padding-right: 35px;
  }
  .how-it-work {
    height: 240px !important;
  }

  .how-it-worked {
    margin-top: 5%;
  }

  .card-container {
    margin-top: 2px;
  }

  .cont-heading {
    font-size: 28px;
  }

  .cont-heading-email {
    font-size: 21px;
  }

  .how-it-worked {
    width: 100%;
    margin-top: 7%;
    // height:30vh;
    background: #0d61bb;
  }
  .footer-content {
    font-size: 14px;
  }
  .footer-content2 {
    font-size: 14px;
  }
}

@media screen and (max-width: 430px) {
  .cont-heading {
    font-size: 25px;
    font-family: "Inter";
  }

  .cont-heading-email {
    font-size: 19px;
    font-family: "Inter";
  }
  .cont-subtitle {
    text-align: center;
  }
}

@media screen and (max-width: 346px) {
  .cont-heading {
    font-size: 21px;
    font-family: "Inter";
  }

  .cont-heading-email {
    font-size: 16px;
    font-family: "Inter";
  }
}

.FontFamily {
  font-family: "Inter";
}

.equal-height-image {
  height: 112.402px;
  width: 193.616px;
  position: relative;
  top: 0px;
  left: 0px;
}

.equal-height-image-azure {
  height: 70px;
  /* Adjust the height as needed */
}

.equal-height-image-gcp {
  height: 70px;
}

.submit-button {
  border: 2px solid white;
  padding: 16px 40px 16px 40px;

  font-weight: 600;
  padding: 12px;
  font-size: 18px;
  width: 100%;
  background: rgba(13, 97, 187, 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.17);
}

.footerheadings {
  color: #fff;

  text-shadow: 0px 6px 4px rgba(0, 0, 0, 0.08);
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media screen and (max-width: 840px) {
  .submit-button {
    padding: 12px;
    font-size: 14px;
    width: 100%;
    border: 2px solid white;
    padding: 16px 40px 16px 40px;

    font-weight: 600;
    padding: 12px;

    width: 100%;
    background: rgba(13, 97, 187, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.17);
  }
}

@media screen and (max-width: 767px) {
  .submit-button {
    padding: 10px;
    font-size: 16px;
    width: 50%;
    border: 2px solid white;
    padding: 16px 40px 16px 40px;

    font-weight: 600;

    background: rgba(13, 97, 187, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.17);
  }
  .form-field {
    padding: 10px;
  }
}

.placeholder-white::placeholder {
  color: darkgray;
}

.cloud-way {
  width: 100%;
  height: 35vh;
  background: F8F9FB;
}

.cloud-way-text {
  color: #0d61bb;
  font-size: 50px;
}

@media screen and (max-width: 600px) {
  .cloud-way-text {
    color: #0d61bb;
    font-size: 40px;
  }

  .cloud-way {
    width: 100%;
    height: 30vh;
    background: F8F9FB;
  }
}

@media screen and (max-width: 414px) {
  .cloud-way-text {
    font-size: 30px;
  }
}

@media screen and (max-width: 414px) {
  .cloud-way-text {
    font-size: 25px;
  }
}

.text_color {
  color: #343434;
}

//   custom footer styling

.custom-footer {
  width: 100%;
  // height:40vh;
  background: #0d61bb;
}

.button-content {
  color: #fff;
  font-family: "Inter";
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 165.523%;
}

.choose-file {
  // width: 50%;
  width: 100%;
  margin-right: -10px;
  // margin-left: 50%;
  // padding:9px;
  border: 1px solid #505050;
  color: #ffffff;
  // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: "376px") {
  .equal-height-image-gcp1 {
    height: 60px;
    position: relative;
    left: 5px;
  }

  .landing_banner_button {
    margin-left: 0px;
  }
}

@media screen and (max-width: 371px) {
  .choose-file {
    font-size: 15.5px;
  }
}

@media screen and (max-width: 361px) {
  .choose-file {
    font-size: 15px;
  }
}

@media screen and (max-width: 352px) {
  .choose-file {
    font-size: 14.7px;
  }
}

@media screen and (max-width: 348px) {
  .choose-file {
    font-size: 14.5px;
    // padding-bottom: 13px;
  }
}

@media screen and (max-width: 344px) {
  .choose-file {
    font-size: 14.1px;
  }
}

@media screen and (max-width: 338px) {
  .choose-file {
    font-size: 13.8px;
  }
}

@media screen and (max-width: 334px) {
  .choose-file {
    font-size: 13.5px;
  }
}

@media screen and (max-width: 328px) {
  .choose-file {
    font-size: 13.3px;
  }
}

@media screen and (max-width: 326px) {
  .choose-file {
    font-size: 13px;
    // padding-bottom: 14px;
  }
}

@media screen and (max-width: 321px) {
  .choose-file {
    font-size: 12.9px;
    // padding-bottom: 13px;
  }
}

@media screen and (max-width: 319px) {
  .choose-file {
    font-size: 12px;
    // padding-bottom: 14px;
  }
}

@media screen and (max-width: 309px) {
  .choose-file {
    font-size: 12px;
  }
}

@media screen and (max-width: 306px) {
  .choose-file {
    font-size: 11px;
  }
}

.button-upload {
  margin-top: 13px;
  // width:26%;
  width: 280px;
  height: 55px;
}

.margin--le {
  margin-left: 50px;
}

.imagewidth {
  width: 120px;
}

.firstimagewidth {
  width: 91.71px;
  height: 108.25px;
  margin-left: 10px;
  margin-top: 7px;
}

.secondimagewidth {
  width: 79.44px;
  height: 100.82px;
  margin-left: 10px;
  margin-top: 7px;
}

.thirdimagewidth {
  width: 114.88px;
  height: 103.6px;
  margin-left: 10px;
  margin-top: 7px;
}

@media screen and (max-width: 1599px) {
  .first-image {
    // margin-top: 13%;
  }
}

@media screen and (max-width: 769px) {
  .margin--le {
    margin-left: 0px;
  }

  .imagewidth {
    width: 120px;
  }

  .first-image {
    // margin-top: 1%;
  }
}

@media screen and (max-width: 651px) {
  .fontSizeapply {
    font-size: 14px;
  }
}

@media screen and (max-width: 577px) {
  .first-image {
    margin-top: 0%;
  }

  .fontSizeapply {
    font-size: 18px;
  }

  .fontSizep {
    font-size: 16px;
  }
  .content-paraa {
    font-size: 16px;
  }

  .imagewidth {
    // height: 100px;
    width: 110px;
  }
}

.margin--left {
  margin-left: 80px;
}

@media screen and (max-width: 1398px) {
  .margin--left {
    margin-left: 50px;
  }
}

@media screen and (max-width: 998px) {
  .margin--left {
    margin-left: 27px;
  }
}

@media screen and (max-width: 769px) {
  .margin--left {
    margin-left: 0px;
  }
}

.mar-le {
  margin-left: 56px;
  width: 85%;
}

@media screen and (max-width: 460px) {
  .mar-le {
    margin-left: 10px;
    width: 94%;
  }
}

@media screen and (max-width: 360px) {
  .mar-le {
    margin-left: 10px;
    width: 90%;
  }
  .fontSizep {
    font-size: 14px;
  }
  .content-paraa {
    font-size: 14px;
  }
}

.customfooter {
  margin-left: 5%;
  font-size: 22px;
}

.customfooter p {
  margin-top: 2%;
  text-align: left;
}

@media screen and (max-width: 989px) {
  .customfooter {
    margin-left: 4%;
    font-size: 21px;
  }

  .contentsection {
    margin-left: -2%;
  }
}

@media screen and (max-width: 770px) {
  .customfooter {
    padding-right: 50px;
    font-size: 22px;
  }

  .contentsection {
    margin-left: -2%;
  }

  .customfooter p {
    margin-top: 2%;
    text-align: center;
  }
}

.footerlink {
  font-size: 18px;
}

.commingSoon {
  width: 40%;
}

@media screen and (max-width: 998px) {
  .commingSoon {
    width: 50%;
  }
}
