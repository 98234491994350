.compare-main-container{
    background-color: white;
    border: 1px solid #DEE2E6;
    border-radius: 6px;
  }
  .compare-recommendation-table-head{
    font-size: 18px;
    font-weight: 700 
  }
  .compare-conter-head{
    padding-left: 21px !important ;
  }
  .recommendation-tab-data{
    height: 250px;
  }
  .fixTableHead {
    overflow-y: auto;
    height: 15em;
  }
   .fixTableHead thead th {
    position: sticky;
    top: 0;
  }
  .flex-container {
    display: flex;
    flex-direction: row;
    font-size: 16px;
  }
  
  .flex-item-left {
    padding: 5px;
    // margin-left:10px;
    margin-bottom: 10px;
    flex: 50%;
  }
  
  .flex-item-right {
    padding: 5px;
    flex: 50%;
    margin-bottom: 10px;
    // margin-left:10px;
  }
  .options-container-compare {
    width: 100%;
  }
  .recommendation-table-header{
    background-color: #e5e9f4 !important;
    font-size: 14px;
    font-weight: 400;
  }
  .recommendation-table-header-azure{
    background-color: #e6f0f5 !important;
    font-size: 14px;
    font-weight: 400;
  }
  .recommendation-table-header-aws{
    background-color: #f7eedf !important;
    font-size: 14px;
    font-weight: 400;
  }
  .recommendation-instance-align{
    text-align: center !important;
  }
  .compare-head-weight{
    font-weight: 400 !important;
  }
  
  /* Responsive layout - makes a one column-layout instead of two-column layout */
  @media (max-width:871px) {
    .flex-container {
      font-size: 14px;
    }
  }
  @media (max-width: 800px) {
    .flex-container {
      flex-direction: column;
      font-size: 14px;
    }
    .flex-item-left {
      padding: 0px;
      // margin-left:10px;
      margin-bottom: 0px;
      flex: 50%;
    }
  
    .flex-item-right {
      padding: 0px;
      flex: 50%;
      margin-bottom: 0px;
    }
  }