@import "~bootstrap/scss/bootstrap";

@import "variables";
@import "common";
@import "otp";
@import "custom-searchbox";
@import 'landing';
@import 'custom-footer';
@import '_compare';
@import "contact";
@import 'db-server-selection';

.Toastify__toast{
  min-height: 42px;
  .Toastify__toast-body {
    font-size: 0.875rem;    
  } 
}
body {
  font-family: 'Inter';
}

