.search-form {
    display: flex;
    justify-content: center;
  }
  
  // .search-input {
  //   border-radius: 20px;
  //   border: none;
  //   box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  //   width: 400px;
  //   padding: 10px 20px;
  //   font-size: 16px;
  //   color: #333;
  // }
  
  // .search-input:focus {
  //   outline: none;
  //   box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  // }
  
  
  