.containerdb{
    //height: 490px !important;
    border-radius: 0px 10px 10px 10px !important;
    background-color: white !important;
    border: 1px solid #ececec !important;
}
.containerdb1{
    display:flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}
.error{
    color: red !important;
    font-size: 10px !important;
    margin-bottom: -10px !important;
}
.editSid{
    cursor: pointer !important;
    margin-left: 5px !important;
    margin-bottom: -5px !important;
}
.customBtn{
    float: right !important;
}
.Btn{
    font-weight:600 !important;
}
.wrap {
// height: 400px !important;
 margin-top: 60px !important;
}
.acordianHeader{
    color: white !important;
    display: flex !important;
    align-items: center !important;
}
.dashboard{
    margin-left: "3px" !important;
}
.app-server{
        display: flex !important;
        align-items: center !important;
        margin-top: 9px !important;
}
.dbtable{
    width: 100% !important;
   border-collapse: collapse !important;
}
.tableBody{
    border-radius: 0px 0px 10px 10px !important;
    border: 1px solid #E3E3E3 !important;
    background-color: #FFF !important;
}
.tbody{
    height: 50px;
}
.storage{
    border-radius: 6px !important;
    background-color: #F7FAFC !important;
}
.storageSpace{
    width: 100px !important;
    padding: 0.375rem 0.75rem !important;
}
.storageType{
    border: 1px solid  #CBD5E0 !important;
    width: 110px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}
.storageCode{
    border: 1px solid #CBD5E0 !important;
    width: 110px !important;
    //padding: "0.375rem 0.75rem",
    // marginTop: "8px",
}
.dbOS{
    border: 1px solid  #CBD5E0 !important;
    width: 200px !important;
    padding: 0.375rem 0.75rem !important; 
    // marginTop: "8px"
}
.dbCount{
    width: 50px !important;
    padding: 0.375rem 0.75rem !important; 
}
.operatingSystem{
    border: 1px solid  #CBD5E0 !important;
    width: 200px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}
.appServerCount{
    width: 50px !important;
}
.appDROperatingSystem{
    border: 1px solid  #CBD5E0 !important;
    width: 200px !important;
}