#custom-footer-main {
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: -6px;
  }
  .custom-footer-main-two {
    width: 1300px;
  }
  #custom-footer-heading {
    position: relative;
    left: 130px;
    top: 13px;
  }
  #custom-footer-img {
    position: relative;
    top: 70px;
    left: 20px;
  }
  #custom-footer-paragraph {
    position: relative;
    left: -180px;
    display: flex;
    top: 86px;
    font-size: 20px;
  }
  .logos-main-outer{
    display: flex;
    position: relative;
    left: 350px;
    top: 15px;
  }
  #custom-footer-logo {
    background-color: rgba(128, 128, 128, 0.499);
    padding: 11px 14px 18px 14px;
    height: fit-content;
    width: fit-content;
    margin: 10px;
    cursor: pointer;
  }
  #custom-footer-logos-paragraph {
    width: fit-content;
  }
  #about-page-custom-footer-adjustment {
    margin-top: 2000px;
  }
  @media screen and (min-width: "1300px") {
    #custom-footer-img {
      position: relative;
      top: 70px;
      left: 0px;
    }
    #custom-footer-paragraph {
      position: relative;
      left: -190px;
      display: flex;
      top: 86px;
      font-size: 20px;
    }
    #custom-footer-logo {
      background-color: rgba(128, 128, 128, 0.499);
      padding: 11px 14px 18px 14px;
      height: fit-content;
      width: fit-content;
      margin: 10px;
      cursor: pointer;
    }
    #custom-footer-heading {
      position: relative;
      left: 100px;
      top: 13px;
    }
    #about-page-custom-footer-adjustment {
      margin-top: 2100px;
    }
    .logos-main-outer{
      display: flex;
      position: relative;
      left: 340px;
      top: 15px;
    }
  }
  @media screen and (max-width :"1237px"){
    #custom-footer-heading {
      position: relative;
      left:100px;
      top: 13px;
    }
  }
  @media screen and (max-width: "1199px"){
    #custom-footer-paragraph {
      position: relative;
      left: -200px;
      display: flex;
      top: 86px;
      font-size: 20px;
  }
  .logos-main-outer {
    display: flex;
    position: relative;
    left: 320px;
    top: 15px;
  }
  }
  @media screen and (max-width: "1132px"){
  //   #custom-footer-img {
  //     position: relative;
  //     top: -10px;
  //     left: 300px;
  // }
  // #custom-footer-heading {
  //   position: relative;
  //   left:100px;
  //   top: 13px;
  // }
  // #custom-footer-paragraph {
  //   position: relative;
  //   left: -190px;
  //   display: flex;
  //   top: 86px;
  //   font-size: 20px;
  // }
  }
  @media screen and (max-width: "1299px") {
    #about-page-custom-footer-adjustment {
      margin-top: 1900px;
    }
  }
  @media screen and (max-width: "1280px"){
    #about-page-custom-footer-adjustment {
      margin-top: 1800px;
    }
  }
  @media screen and (max-width: "1280px") and (min-height: "800px"){
    #about-page-custom-footer-adjustment {
      margin-top: 1800px;
    }
  }
  @media screen and (max-width: "1185px"){
    #custom-footer-img {
      position: relative;
      top: 15%;
      left: 105%;
  }
  #custom-footer-heading {
    color: rgb(255, 255, 255);
    position: relative;
    top: -10%;
    left: 130%;
  }
  .logos-main-outer {
    display: flex;
    position: relative;
    left: -20%;
    top: 21%;
  }
  #custom-footer-paragraph {
    position: relative;
    left: -40%;
    display: flex;
    top: 70%;
    font-size: 20px;
  }
  }
  @media screen and (max-width: "1114px") {
    #about-page-custom-footer-adjustment {
      margin-top: 1800px;
    }
  }
  @media screen and (max-width: "1095px"){
    .logos-main-outer {
      display: flex;
      position: relative;
      left: -17%;
      top: 21%;
    }
  }
  @media screen and (max-width: "1054px"){
    #custom-footer-img {
      position: relative;
      top: 13%;
      left: 100%;
  }
  #custom-footer-heading {
    color: rgb(255, 255, 255);
    position: relative;
    top: -10%;
    left: 125%;
  }
  }
  @media screen and (max-width: "1024px") {
    // #custom-footer-paragraph {
    //   position: relative;
    //   left: -155px;
    //   display: flex;
    //   top: 88px;
    //   font-size: 17px;
    // }
    // #custom-footer-logo {
    //   background-color: rgba(128, 128, 128, 0.499);
    //   padding: 5px;
    //   height: 26px;
    //   width: 26px;
    //   margin: 7px;
    //   position: relative;
    //   left: 310px;
    //   top: 40px;
    //   border-radius: 5px;
    //   cursor: pointer;
    // }
  }
  @media screen and (max-width: "1024px") and (min-height: "1366px") {
    #about-page-custom-footer-adjustment {
      margin-top: 2180px;
    }
  }
  @media screen and (max-width: "993px"){
    #custom-footer-img {
      position: relative;
      top: 13%;
      left: 55%;
  }
  }
  @media screen and (width: 992px) {
    #custom-footer-img {
        position: relative;
        top: 13%;
        left: 100%;
    }
  }
  @media screen and (max-width:"934px"){
    #custom-footer-img {
      position: relative;
      top: 30%;
      left: 50%;
  }
  #custom-footer-logo {
    background-color: rgba(128, 128, 128, 0.499);
    padding: 11px 14px 18px 14px;
    height: -moz-fit-content;
    height: fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 10px;
    cursor: pointer;
    position: relative;
    top: 70px;
  }
  #custom-footer-heading {
    color: rgb(255, 255, 255);
    position: relative;
    top: 6%;
    left: 120%;
  }
  #custom-footer-paragraph {
    position: relative;
    left: -40%;
    display: flex;
    top: 80%;
    font-size: 20px;
  }
  .logos-main-outer {
    display: flex;
    position: relative;
    left: -20%;
    top: 10%;
  }
  }
  @media screen and (max-width: "912px") {
    #custom-footer-heading {
      color: rgb(255, 255, 255);
      position: relative;
      top: -4%;
      left: 130%;
  }
  #custom-footer-img {
    position: relative;
    top: 20%;
    left: 60%;
  }
  #custom-footer-logo {
    background-color: rgba(128, 128, 128, 0.499);
    padding: 11px 14px 18px 14px;
    height: -moz-fit-content;
    height: fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 10px;
    cursor: pointer;
    position: relative;
    top: 40px;
  }
  #custom-footer-paragraph {
    position: relative;
    left: -35%;
    display: flex;
    top: 70%;
    font-size: 20px;
  }
  .logos-main-outer {
    display: flex;
    position: relative;
    left: -12%;
    top: 10%;
  }
    #about-page-custom-footer-adjustment {
      margin-top: 1800px;
    }
  }
  @media screen and (max-width: "890px") {
    .logos-main-outer {
      display: flex;
      position: relative;
      left: -10%;
      top: 10%;
  }
  }
  @media screen and (max-width: "829px"){
    #custom-footer-img {
      position: relative;
      top: 43%;
      left: 60%;
  }
  #custom-footer-heading {
    color: rgb(255, 255, 255);
    position: relative;
    top: 20%;
    left: 130%;
  }
  .logos-main-outer {
    display: flex;
    position: relative;
    left: 25%;
    top: -210%;
  }
  #custom-footer-paragraph {
    position: relative;
    left: 5%;
    display: flex;
    top: -65%;
    font-size: 20px;
  }
  }
  @media screen and (max-width: "820px") {
  //   #custom-footer-img {
  //     position: relative;
  //     top: 25%;
  //     left: 60%;
  // }
  // #custom-footer-heading {
  //   color: rgb(255, 255, 255);
  //   position: relative;
  //   top: 2%;
  //   left: 130%;
  // }
  #custom-footer-paragraph {
    position: relative;
    left: 6%;
    display: flex;
    top: -60%;
    font-size: 20px;
  }
  .logos-main-outer {
    display: flex;
    position: relative;
    left: 25%;
    top: -200%;
  }
    #about-page-custom-footer-adjustment {
      margin-top: 1700px;
    }
  }
  @media screen and (max-width: "774px"){
    #custom-footer-heading {
      color: rgb(255, 255, 255);
      position: relative;
      top: -45%;
      left: 130%;
  }
  .logos-main-outer {
    display: flex;
    position: relative;
    top: -140%;
  }
  #custom-footer-img {
    position: relative;
    top: 17%; 
    left: 60%;
  }
  #custom-footer-paragraph {
    position: relative;
    left: 6%;
    display: flex;
    top: 10%;
    font-size: 20px;
  }
  }
  @media screen and (max-width: "770px"){ 
    #custom-footer-heading {
      color: rgb(255, 255, 255);
      position: relative;
      top: -50%;
      left: 150%;
  }
  #custom-footer-img {
    position: relative;
    top: 15%;
    left: 80%;
  }
  #custom-footer-img {
    position: relative;
    top: 15%;
    left: 80%;
  }
  #custom-footer-logo {
    background-color: rgba(128, 128, 128, 0.499);
    padding: 11px 14px 18px 14px;
    height: -moz-fit-content;
    height: fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 10px;
    cursor: pointer;
    position: relative;
    top: 0px;
  }
  
  
  .logos-main-outer {
    display: flex;
    position: relative;
    left: 25%;
    top: -110%;
  }
  }
  @media screen and (max-width: "768px") {
  //   #custom-footer-img {
  //     position: relative;
  //     top: 10%;
  //     height: 45px;
  //     left: -12%;
  // }
  // #custom-footer-heading {
  //   position: relative;
  //   left: 3%;
  //   top: -40%;
  //   font-size: 15px;
  // }
  }
  @media screen and (max-width: "766px"){
    #custom-footer-img {
      position: relative;
      top: 35%;
      left: -13%;
  }
  #custom-footer-heading {
    color: rgb(255, 255, 255);
    position: relative;
    top: -40%;
    left: 8%;
  }
  }
  @media screen and (max-width: "627px"){
    #custom-footer-heading {
      color: rgb(255, 255, 255);
      position: relative;
      top: -35%;
      left: 10%;
  }
  }
  @media screen and (max-width: "616px"){
    #custom-footer-img {
      position: relative;
      top: 40%;
      left: -13%;
  }
  #custom-footer-heading {
    color: rgb(255, 255, 255);
    position: relative;
    top: -30%;
    left: 12%;
  }
  }
  @media screen and (max-width: "590px"){
    #custom-footer-img {
      position: relative;
      top: 45%;
      left: -18%;
  }
  #custom-footer-heading {
    color: rgb(255, 255, 255);
    position: relative;
    top: -30%;
    left: 7%;
  }
    #custom-footer-paragraph {
      position: relative;
      left: 0%;
      display: flex;
      top: 0%;
      font-size: 20px;
      padding: 0% 10%;
  }
  }
  @media screen and (max-width: "540px") {
    #custom-footer-img {
      position: relative;
      top: 15%;
      left: -18%;
  }
    #custom-footer-heading {
      position: relative;
      left: 5%;
      top: -54%;
      font-size: 15px;
  }
  #custom-footer-paragraph {
    position: relative;
    left: 0%;
    display: flex;
    top: 0%;
    font-size: 20px;
  }
  .logos-main-outer {
    display: flex;
    position: relative;
    left: 23%;
    top: -110%;
  }
    #about-page-custom-footer-adjustment {
      margin-top: 1400px;
    }
  }
  @media screen and (max-width: '460px'){
    #custom-footer-heading {
      position: relative;
      left: 7%;
      top: -54%;
      font-size: 15px;
  }
  }
  @media screen and (max-width: "430px") {
    #custom-footer-heading {
      position: relative;
      left: 10%;
      top: -60%;
      font-size: 15px;
      display: none;
  }
  #custom-footer-img {
    position: relative;
    top: 0%;
    height: 45px;
    left: 0%;
  }
  #custom-footer-logo {
    background-color: rgba(128, 128, 128, 0.499);
    padding: 8px 10px;
    height: -moz-fit-content;
    height: fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 9px;
    cursor: pointer;
  }
  #custom-footer-paragraph {
    position: relative;
    left: 0%;
    display: flex;
    top: 0%;
    font-size: 13px;
    padding: 0%;
  }
  #custom-footer-logo svg{
    height: 14px;
    width: 15px;
  }
  .logos-main-outer {
    display: flex;
    position: relative;
    left: 18%;
    top: -120%;
  }
    #about-page-custom-footer-adjustment {
      margin-top: 1200px;
    }
  }
  @media screen and (max-width: "412px") and (min-height: "915px") {
    #about-page-custom-footer-adjustment {
      margin-top: 1250px;
    }
  }
  @media screen and (max-width: "400px") {
    #about-page-custom-footer-adjustment {
      margin-top: 1200px;
    }
    .logos-main-outer {
      display: flex;
      position: relative;
      left: 18%;
      top: -120%;
  }
  }
  @media screen and (max-width: "390px"){
    #about-page-custom-footer-adjustment {
      margin-top: 1200px;
    }
  }
  @media screen and (max-width: "360px"){
    #about-page-custom-footer-adjustment {
      margin-top: 1150px;
    }
  }
  @media screen and (max-width: "280px") {
    #about-page-custom-footer-adjustment {
      margin-top: 1100px;
    }
    .logos-main-outer {
      display: flex;
      position: relative;
      left: 13%;
      top: -120%;
  }
  }
  