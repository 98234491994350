@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body {
  padding-top: 4rem;
}

main {
  min-height: calc(100vh - 72px);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
 // background-color: $background-color;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

// .App-link {
//   color: $color_blue;
// }

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.steps-container {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
}

.step-wrapper {
  width: 25%;
  position: relative;
}

.step {
  text-align: center;
  cursor: default;
}

.step .line {
  height: 0px;
  border-width: 1px;
  border-style: solid;
  position: absolute;
  width: 100%;
  left: 50%;
  top: 18px;
  z-index: 0.9;
  /* Set a z-index for the line */
  border-color: #eee;
}

.step .circle {
  padding: 6px 14px;
  display: inline-block;
  margin-bottom: 14px;
  box-shadow: 0 0 0 10px;
  position: relative;
  z-index: 1;
  /* Set a higher z-index for the circles */
}

.step .circle {
  padding: 6px 14px;
  display: inline-block;
  margin-bottom: 14px;
  box-shadow: 0 0 0 10px;
}

.step-active .circle {
  background: #4fc123;
  color: #fff;
  box-shadow: 0 0 0 10px #bceca9;
}

.step-done .circle {
  background: #0d61bb;
  color: #eee;
  box-shadow: 0 0 0 10px #aec9e5;
}

.step-inactive .circle {
  background: #bbb;
  color: #000;
  box-shadow: 0 0 0 10px #e6e5e5;
}

.step-active .label {
  color: #000;
}

.step-done .label {
  color: #222;
}

.step-inactive .label {
  color: #888;
}

// .step-done .line {
//   border-color: #0D61BB ;
// }

// .step-inactive .line,
// .step-active .line {
//   border-color: #eee;
// }

.stepper-button-container {
  width: 100%;
  gap: 10px;
  display: flex;
  justify-content: end;
}

.report-back-button {
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.options-card {
  height: 220px;
}

.cursor-pointer {
  cursor: pointer;
}

.region-options {
  display: flex;
  justify-content: space-between;
}

.instance-type {
  display: flex;
  justify-content: space-between;

  .cpu-count {
    display: flex;
    justify-content: space-between;
  }
}

.uploaded-data-sid {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.options-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.quotation-title {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .report-buttons {
    width: 150px;
    display: flex;
    justify-content: space-between;

    button {
      min-width: 120px;
    }
  }
}

.accordion-item:first-of-type .accordion-button {
  width: 100%;
  height: 50px;

  flex-shrink: 0;
  border-radius: 10px 10px 0px 0px;
  background: #0d61bb;
  color: #fff;
  padding: 0 10px 10px 10px;
  margin-top: -20px;

  text-shadow: 0px 6px 4px rgba(0, 0, 0, 0);

  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 164.023%;
}

.accordion-item:last-of-type .accordion-button {
  width: 100%;
  height: 50px;

  flex-shrink: 0;
  border-radius: 10px 10px 0px 0px;
  background: #0d61bb;
  color: #fff;
  padding: 20px;

  text-shadow: 0px 6px 4px rgba(0, 0, 0, 0);

  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 164.023%;
}

.cloud-title-text {
  color: #fff;
  padding: 20px;

  text-shadow: 0px 6px 4px rgba(0, 0, 0, 0);

  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 164.023%;
  /* 29.524px */
}

.cloud-sid {
  color: #000;
  margin-left: 20px;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.float-container {
  border: 3px solid #fff;
  padding: 40px;
}

.float-child {
  width: 50%;
  float: left;
  padding: 10px 10px 0 10px;
  align-items: center;
}

.table-row {
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
  font-weight: bold;
  border-top: 1px solid #dee2e6;
  color: #212529;
}

.table-row > div {
  flex: 1;
  padding: 0.75rem;
  display: flex;
  align-items: center;
}

.table-row > div:first-child {
  border-right: 1px solid #dee2e6;
}

.step-container {
  width: 140px;
  display: flex;
  justify-content: space-between;
}

.icons {
  cursor: pointer;
}

.disableicon {
  cursor: pointer;
  color: #ccc;
}

.quotation-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;

  button {
    min-width: 100px;
  }
}

.pdf-table-bold-row {
  font-weight: bold;
}

.sizing-report-wrapper {
  text-align: right;
}

.quotation-buttons-wrapper {
  display: flex;
  justify-content: flex-end;
}

.report-name-buttons {
  text-align: right;
  padding-top: 10px;
}

.sid-column {
  width: 100px !important;
}

.report-name-column {
  width: 380px !important;
}

.amount-column {
  text-align: right;
  width: 140px;
}

.created-at-colum {
  width: 200px !important;
}

.action-button-column {
  text-align: center;
}

.action-button {
  width: 140px !important;
  text-align: center;

  button {
    margin-right: 4px;
  }
}

/* Custom styles for the modal dialog */
.report-name-modal {
  background-color: #2c2c2c8c !important;
}

.dropdown-menu[data-bs-popper] {
  left: -135px;
}

.server-container {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .server-options {
    gap: 3px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }
}

.error-message {
  color: #ff0000;
}
.invalid-feedback div {
  font-size: 12px;
}
.invalid-feedback {
  font-size: 12px;
}
.modal-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.table-container {
  flex-grow: 1;
  overflow-y: auto;
  padding-right: 9px;
}

.quotation-buttons-wrapper {
  position: sticky;
  bottom: 0;
  background-color: white;
  padding: 10px;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
}

.sticky-header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.email-report-buttons {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;

  .email-buttons {
    max-width: 300px;
    gap: 20px;
    display: flex;
  }
}

.plus-icon-container {
  // margin-top: 15px;
  width: 40px;
  height: 40px;
  background-color: #007bff;
  /* Set your desired background color */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  float: right;
}

.plus-icon-container.active {
  background-color: #0056b3;
  /* Change color when active */
}

.custom-dropdown {
  border: 1px solid #6c757d;
}
.is-invalid .form-check {
  border-color: #dc3545;
  background-color: #fae6e7;
}

.custom-modal {
  width: 100vw;
  margin: 0;
}

.dropzone,
.dropzone * {
  box-sizing: border-box;
}

.dropzone {
  min-height: 150px;
  cursor: pointer;
}

.dropzone.dz-clickable {
  cursor: pointer;
}

.dropzone.dz-clickable * {
  cursor: default;
}

.dropzone.dz-clickable .dz-message,
.dropzone.dz-clickable .dz-message * {
  cursor: pointer;
}

.dropzone.dz-started .dz-message {
  display: none;
}

.dropzone.dz-drag-hover {
  border-style: solid;
}

.dropzone.dz-drag-hover .dz-message {
  opacity: 0.5;
}

.dropzone .dz-preview.dz-file-preview .dz-details,
.dropzone .dz-preview:hover .dz-details {
  opacity: 1;
}

.dropzone .dz-message {
  text-align: center;
  margin: 2em 0;
}

.dropzone .dz-preview {
  position: relative;
  display: inline-block;
  vertical-align: top;
  /* margin: 16px; */
  margin: 5px;
  min-height: 100px;
}

.dropzone .dz-preview:hover {
  z-index: 1000;
}

.dropzone .dz-preview.dz-file-preview .dz-image {
  border-radius: 20px;
  background: #999;
  background: linear-gradient(to bottom, #eee, #ddd);
}

.dropzone .dz-preview.dz-image-preview {
  background: #fff;
}

.dropzone .dz-preview.dz-image-preview .dz-details {
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -ms-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.dropzone .dz-preview .dz-remove {
  font-size: 14px;
  text-align: center;
  display: block;
  cursor: pointer;
  border: none;
}

.dropzone .dz-preview .dz-remove:hover {
  text-decoration: underline;
}

.dropzone .dz-preview .dz-details {
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  font-size: 13px;
  min-width: 100%;
  max-width: 100%;
  padding: 2em 1em;
  text-align: center;
  color: rgba(0, 0, 0, 0.9);
  line-height: 150%;
}

.dropzone .dz-preview .dz-details .dz-size {
  margin-bottom: 1em;
  font-size: 16px;
}

.dropzone .dz-preview .dz-details .dz-filename {
  white-space: nowrap;
}

.dropzone .dz-preview .dz-details .dz-filename:hover span {
  border: 1px solid rgba(200, 200, 200, 0.8);
  background-color: rgba(255, 255, 255, 0.8);
}

.dropzone .dz-preview .dz-details .dz-filename:not(:hover) {
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropzone .dz-preview .dz-details .dz-filename:not(:hover) span {
  border: 1px solid transparent;
}

.dropzone .dz-preview .dz-details .dz-filename span,
.dropzone .dz-preview .dz-details .dz-size span {
  background-color: rgba(255, 255, 255, 0.4);
  padding: 0 0.4em;
  border-radius: 3px;
}

.dropzone .dz-preview:hover .dz-image img {
  -webkit-transform: scale(1.05, 1.05);
  -moz-transform: scale(1.05, 1.05);
  -ms-transform: scale(1.05, 1.05);
  -o-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
  -webkit-filter: blur(8px);
  filter: blur(8px);
}

.dropzone .dz-preview .dz-image {
  border-radius: 20px;
  overflow: hidden;
  width: 120px;
  height: 120px;
  position: relative;
  display: block;
  z-index: 10;
}

.dropzone .dz-preview .dz-image img {
  display: block;
}

.dropzone .dz-preview.dz-success .dz-success-mark {
  -webkit-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -o-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.dropzone .dz-preview.dz-error .dz-error-mark {
  opacity: 1;
  -webkit-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -o-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.dropzone .dz-preview .dz-error-mark,
.dropzone .dz-preview .dz-success-mark {
  opacity: 0;
  z-index: 500;
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  margin-left: -27px;
  margin-top: -27px;
}

.dropzone .dz-preview .dz-error-mark svg,
.dropzone .dz-preview .dz-success-mark svg {
  display: block;
  width: 54px;
  height: 54px;
}

.dropzone .dz-preview.dz-processing .dz-progress {
  opacity: 1;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.dropzone .dz-preview.dz-complete .dz-progress {
  opacity: 0;
  -webkit-transition: opacity 0.4s ease-in;
  -moz-transition: opacity 0.4s ease-in;
  -ms-transition: opacity 0.4s ease-in;
  -o-transition: opacity 0.4s ease-in;
  transition: opacity 0.4s ease-in;
}

.dropzone .dz-preview:not(.dz-processing) .dz-progress {
  -webkit-animation: pulse 6s ease infinite;
  -moz-animation: pulse 6s ease infinite;
  -ms-animation: pulse 6s ease infinite;
  -o-animation: pulse 6s ease infinite;
  animation: pulse 6s ease infinite;
}

.dropzone .dz-preview .dz-progress {
  opacity: 1;
  z-index: 1000;
  pointer-events: none;
  position: absolute;
  height: 16px;
  left: 50%;
  top: 50%;
  margin-top: -8px;
  width: 80px;
  margin-left: -40px;
  background: rgba(255, 255, 255, 0.9);
  -webkit-transform: scale(1);
  border-radius: 8px;
  overflow: hidden;
}

.dropzone .dz-preview .dz-progress .dz-upload {
  background: #333;
  background: linear-gradient(to bottom, #666, #444);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  -webkit-transition: width 0.3s ease-in-out;
  -moz-transition: width 0.3s ease-in-out;
  -ms-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
}

.dropzone .dz-preview.dz-error .dz-error-message {
  display: block;
}

.dropzone .dz-preview.dz-error:hover .dz-error-message {
  opacity: 1;
  pointer-events: auto;
}

.dropzone .dz-preview .dz-error-message {
  pointer-events: none;
  z-index: 1000;
  position: absolute;
  display: block;
  display: none;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  border-radius: 8px;
  font-size: 13px;
  top: 130px;
  left: -10px;
  width: 140px;
  background: #be2626;
  background: linear-gradient(to bottom, #be2626, #a92222);
  padding: 0.5em 1.2em;
  color: #fff;
}

.dropzone .dz-preview .dz-error-message:after {
  content: "";
  position: absolute;
  top: -6px;
  left: 64px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #be2626;
}

.heading-label {
  color: black;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  /* 150% */
  letter-spacing: -0.36px;
  margin-bottom: 5px;
}

.custom-custom {
  width: 457px;
  height: 41px;
}

.label-or {
  color: #000;

  text-align: center;

  font-size: 46px;
  font-style: normal;
  font-weight: 600;
  line-height: 50px;
  /* 52.083% */
  letter-spacing: -1.44px;
}

.form-border {
  border: 2px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 40px;
}
.custom-input-placeholder::placeholder {
  color: #909090 !important;
  font-size: 12px !important;
}
.form-control::placeholder {
  color: #909090 !important;
  font-size: 12px !important;
}

select:disabled {
  color: gray;
}
.check-input.is-invalid .form-check-label {
  color: rgba(0, 0, 0, 0.9) !important;
}

/* Optional: style options to make them appear normal inside the dropdown */
select option {
  color: black;
}
.box {
  padding: 8px 5px;
  display: inline-block;
  background-color: #cfd3d7;
}

.button-spacing {
  margin-right: 10px;
  /* Adjust the value according to your preference */
}

.delete-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
}

.tab-card {
  width: auto;
  height: 667px;
  flex-shrink: 0;
  border-radius: 0px 10px 10px 10px;
  border: 1px solid #dee2e6;

  background: #fff;
}

.tabs {
  border-radius: 4px 4px 0px 0px;
  border-top: 1px solid #dee2e6;

  border-right: 1px solid #dee2e6;

  border-left: 1px solid #dee2e6;

  background: #fff;
  padding: 10px;
}

.tab-container {
  width: auto;
  height: auto;
  padding: 15px;
  flex-shrink: 0;
  border-radius: 0px 10px 10px 10px;
  //border: 1px solid #dee2e6;
  padding-left: 20px;
  background: #f6f7f8;
}

.nav .nav-tabs {
  border-bottom: none;
}

.tab-mid {
  height: 667px;
  flex-shrink: 0;
  border-radius: 0px 10px 10px 10px;
  border: 1px solid #dee2e6;
}

.tab-wrapper {
  height: 620px;

  border-radius: 0px 10px 10px 10px;
  border: 1px solid #dee2e6;
  border-top: none;
  background: #fff;
}

.tabs-box {
  flex-shrink: 0;
  border-radius: 10px;
  border: 0px solid #acacac;
  padding: 15px;
  background: #f6f7f8;
  // padding-top: 10px;
}

ul.nav .nav-tabs {
  border-bottom: none !important;
}

.content {
  height: 466px;
  flex-shrink: 0;
  border-radius: 0px 10px 10px 10px;
  border: 1px solid #dee2e6;

  background: #fff;
}

.rbt-input-main .form-control .rbt-input {
  border: "1px solid  #CBD5E0";
  border-radius: "6px";
  width: "80px";
  padding: "0.375rem 0.75rem";
  margin-top: "8px";
  background-color: "#F7FAFC";
}

.text-text {
  border: "1px solid  #CBD5E0";
  border-radius: "6px";
  width: "80px";
  padding: "0.375rem 0.75rem";
  margin-top: "8px";
  background-color: "#F7FAFC";

  box-shadow: 0px 2px 0px 0px rgba(231, 235, 238, 0.2) inset;
}

.wrapper {
  // height: 466px;
  flex-shrink: 0;
  border-radius: 0px 10px 10px 10px;
  // border: 1px solid #dee2e6;
  padding: 20px;
  background: #fff;
  overflow-y: "auto";
}

.accordion-button {
  margin-bottom: 5px !important;
  margin-top: 15px !important;
}

.accordion-item {
  border: none !important;
}

.accordion-body {
  padding-top: 0px !important;
  max-height: 240px;
  background-color: #0d61bb;
  border-radius: 0px 0px 10px 10px;
  border: 1px solid #dee2e6;
  overflow: hidden;
  background: #fff;
  margin-top: 10px;
  //overflow-x: hidden;
}
.scrollable-table-container {
  max-height: 400px; /* Adjust as necessary */
  overflow-y: auto;
  background-color: pink !important;
}
.edit-report-wrapper {
  margin-left: -60px !important;
}

@media (max-width: 2000px) {
  .edit-report-wrapper {
    margin-left: -42px !important;
  }
}

@media (max-width: 1850px) {
  .edit-report-wrapper {
    margin-left: -35px !important;
  }
}

@media (max-width: 1710px) {
  .edit-report-wrapper {
    margin-left: -25px !important;
  }
}

@media (max-width: 1562px) {
  .edit-report-wrapper {
    margin-left: -18px !important;
  }
}

@media (max-width: 1410px) {
  .edit-report-wrapper {
    margin-left: -10px !important;
  }
}

@media (max-width: 1283px) {
  .accordion-body {
    overflow-x: auto;
  }

  .edit-report-wrapper {
    margin-left: -7px !important;
  }
}

@media (max-width: 1245px) {
  .dashboard-font-Size {
    font-size: 14px;
  }
}

@media (max-width: 1030px) {
  .edit-report-wrapper {
    margin-left: 0.5rem !important;
  }
}

@media (max-width: 768px) {
  .edit-report-wrapper {
    margin-left: 0rem !important;
  }
  .cloud-sid {
    font-size: 18px;
  }
}

.t-body {
  height: 236px;
  width: 100%;
  border-radius: 0px 0px 10px 10px;
  border: 1px solid #dee2e6;

  background: #fff;
}

.custom-scroll {
  // max-height: 506px;
  overflow-y: auto;
}

.custom-scroll::-webkit-scrollbar {
  width: 6px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 50px;
}

.custom-scroll::-webkit-scrollbar-track {
  background-color: #dee2e6;
  border-radius: 50px;
}

// .table-responsive {
//   max-height: 506px;
// }

.table-responsive::-webkit-scrollbar {
  height: 6px;
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 50px;
  width: 8px;
}

.table-responsive::-webkit-scrollbar-track {
  background-color: #dee2e6;
  border-radius: 50px;
}

.accordion-button:not(.collapsed)::after {
  color: "white";
}

.outline-btn {
  border-color: #0d61bb;
  background-color: white;
  color: #0d61bb;
}

.outline-btn:hover {
  color: white;
}

.rbt {
  position: unset !important;
}

.rbt-input-main .form-control .rbt-input {
  border: "1px solid  #CBD5E0";
  border-radius: "6px";
  width: "80px";
  // padding: "0.375rem 0.75rem";
  margin-top: "8px";
  background-color: "#F7FAFC";
}

/* RegionSelect.css */

.region-select-container {
  position: relative;
  width: 100%;
  /* Adjust width as needed */
}

.region-select-container .form-label {
  display: block;
  margin-bottom: 0.5rem;
}

.region-select-container .typeahead {
  width: 100%;
  position: relative;
}

.region-select-container .typeahead .rbt-input {
  border-radius: 6px;
  border: 1px solid #cbd5e0;

  background: #f7fafc;
}

.region-select-container .typeahead .rbt-input-multi .rbt-input-wrapper {
  display: block;
}

.region-select-container .typeahead .rbt-input {
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  width: 100% !important;
}
.options-container-compare .compare-select-container .rbt-input {
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 0.375rem 1.6rem 0.375rem 0.75rem;
  width: 100% !important;
}

.region-select-container .typeahead .rbt-input:focus {
  outline: none;
  box-shadow: none;
  border-color: #80bdff;
}

.region-select-container .dropdown-indicator {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.share-file-box {
  cursor: pointer;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  //text-align: center;
  border-radius: 5px;

  border: 1px solid #cacaca;

  background: #fff;

  box-shadow: 0px -2px 43px 0px rgba(0, 0, 0, 0);
}

.sidebar-content {
  width: 80px;
  overflow: hidden;
  transition: width 0.3s ease;
  // margin-left: -11px;
  min-height: calc(100vh - 72px);
  background-color: #0d61bb;
  z-index: 999;
  bottom: 0px;
  position: fixed;
}

.sidebar-content.expanded {
  width: 230px;
}

.activesidebar {
  background-color: #0b54a3;
  color: white;
  border-radius: 50px;
}

.sidebar-link:hover {
  background-color: #1a426d;
  color: white;
  border-radius: 50px !important;
}

.price-img {
  width: 16px;
  margin-right: 20px;
  margin-top: -7px;
}

.compare-img {
  width: 19px;
  margin-right: 15px;
  margin-top: -7px;
}

.sap-img {
  font-size: 30px;
  margin-right: 7px;
  margin-top: -5px;
}

.admin-icon {
  font-size: 30px;
  margin-right: 7px;
  margin-top: -5px;
}

.sidebar-size {
  font-size: 18px;
}
.tooltip-allow {
  display: none !important;
}

.container-prf {
  height: auto;
  // height: 300px;
  flex-shrink: 0;
  flex-direction: column;
  border-radius: 10px;
  background: #dee2e6;
}

.sc-Nxspf.gmeTLe.toggled .price-img,
.sc-Nxspf.gmeTLe.toggled .compare-img {
  display: inline-block;
  /* Ensure images remain visible */
}

@media (max-width: 768px) {
  .container-prf {
    height: 557px;
    /* Hides the text next to the icon */
  }

  .edit-report-wrapper {
    margin-right: 2px !important;
  }

  .sidebar-content {
    width: 60px;
  }

  .price-img {
    width: 17px;
    margin-right: 20px;
    margin-top: 0px;
  }

  .compare-img {
    width: 24px;
    margin-right: 15px;
    margin-top: 0px;
  }
  .tooltip-allow {
    display: block !important;
  }

  .activesidebar {
    display: flex;
    justify-content: center;
    background-color: #0b54a3;
    color: white;
    border-radius: 0px;
    width: 100%;
    margin: 9px 0px !important;
  }

  .sidebar-link {
    display: flex;
    justify-content: center;
    margin: -10px -15px !important;
  }

  .sidebar-link:hover {
    display: flex;
    justify-content: center;
    background-color: #321fdb;
    color: white;
    border-radius: 0px;
    margin: -10px -15px !important;
  }

  .sidebar-size {
    // font-size: 15px ;
    display: none;
  }

  .sidebar-content.expanded {
    width: 60px;
  }
}

  .gmeTLe.toggled .item-content {
    display: block !important;
    width: auto !important;
  }

.report-fltr {
  margin: 15px;
  border-radius: 10px;
  background: #fff;
  padding: 10px;
}

@media (max-width: 768px) {
  .fw-semibold {
    width: 140%;
    /* Hides the text next to the icon */
  }

  .report-fltr {
    height: auto !important;
    width: auto !important;
  }

  .reportname-style {
    width: 71% !important;
  }
}

@media (max-width: 767px) {
  .system-infos {
    margin-top: 20px !important;
  }
  .small-azure-img {
    width: 211px !important;
  }
  .small-gcp-img {
    width: 200px !important;
  }
}

.demand {
  color: #0d61bb;

  font-size: 20px;

  font-weight: 600;
  line-height: normal;
}

@media (max-width: 568px) {
  .sidebar-content {
    width: 50px;
  }
}

@media (max-width: 568px) {
  .sidebar-content {
    width: 50px;
  }
  .pricing-report-button {
    font-size: 14px !important;
  }
}

@media (max-width: 538px) {
  .demand {
    color: #0d61bb;

    font-size: 16px;

    font-weight: 600;
    line-height: normal;
  }
  .cloud-sid {
    font-size: 16px;
  }
}

.time-picker {
  display: flex;
  align-items: center;
}

.time-picker label {
  margin-right: 8px;
}

.time-picker select {
  padding: 2px;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #cbd5e0;
  color: #4a5568;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  background: #f7fafc !important;
  box-shadow: 0px 2px 0px 0px rgba(231, 235, 238, 0.2) inset !important;
}

.time-picker span {
  padding: 0 2px;
}

.price-label {
  color: #000;
  font-size: 14px;

  font-weight: 400;
  line-height: 20px;
  /* 125% */
}

/* Style the scrollbar itself (background) */
.table-responsive::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f5f5f5;
  /* or any color */
}

/* Style the scrollbar thumb (the draggable part) */
.table-responsive::-webkit-scrollbar-thumb {
  background-color: #bcbbbb;
  /* or any color */
  border-radius: 5px;
}

/* Handle hover state */
.table-responsive::-webkit-scrollbar-thumb:hover {
  background: #e9e8e8;
  /* or any color */
}

/* Default styles - for mobile devices */
.responsive-div {
  width: 100%;

  background-color: lightblue;
  text-align: center;
  display: flex;
  flex-direction: column;
}
// .accordian-heads{
//   font-size: 18px !important;
// }

/* For tablets: */
@media (min-width: 768px) {
  .responsive-div {
    width: 50%;
  }
}

/* For desktops: */
@media (min-width: 1024px) {
  .responsive-div {
    width: 25%;
  }
}

.rbt-input {
  border: 1px solid rgb(203, 213, 224);
  border-radius: 6px;
  width: 130px !important;
  padding: 0.375rem 0.75rem;

  background-color: rgb(247, 250, 252) !important;
}

.nav-tabs {
  border: none;
}

@media (max-width: 776px) {
  /* Adjust breakpoint as needed */
  .button-text {
    display: none;
  }
  .accordian-heads {
    font-size: 14px !important;
  }
}

.icon-text {
  display: none;
}

/* Optional: Adjust button padding for small screens */
@media (max-width: 776px) {
  .button-spacing {
    padding: 0.375rem !important;
  }

  .icon-text {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .hide-text-small {
    display: none;
  }
}

.accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

@media (max-width: 768px) {
  .small-screen {
    font-size: 12px;
  }
}

@media (max-width: 495px) {
  .heading-label {
    font-size: 16px;
  }

  .sidebar-content {
    width: 40px;
  }

  .price-img {
    width: 16px;
    margin-top: 0px;
  }

  .compare-img {
    width: 20px;
    // margin-top: 20px;
  }

  .dashboard-font-Size {
    font-size: 12px !important;
  }
  .accordian-heads {
    font-size: 13px !important;
  }
}

@media (max-width: 435px) {
  .small-screen {
    font-size: 10px;
  }
  .accordian-heads {
    font-size: 12px !important;
    .sm-screen {
      margin-top: 10px;
      padding: 0px !important;
    }
  }
}

@media (max-width: 406px) {
  .small-screen {
    font-size: 8px;
  }
}
@media (max-width: 345px) {
  .small-screen {
    font-size: 7px;
  }
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 5px grey;
  background-color: #dee2e6;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bcbbbb;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e9e8e8;
}

.custom-typeahead .rbt-menu {
  width: auto !important;
}

.total-cost {
  background: rgba(13, 97, 187, 0.1);
  color: #0d61bb;

  padding-top: 4px;
  padding-bottom: 4px;
}
.custom-width {
  @media screen and (max-width: 1180px) {
    width: 70% !important  ;
  }
  @media screen and (max-width: 985px) {
    width: 60% !important  ;
  }
  @media screen and (max-width: 768px) {
    width: 100% !important  ;
  }
}
.custom-responsive-col {
  @media screen and (max-width: 1180px) {
    width: 30% !important;
  }
  @media screen and (max-width: 985px) {
    width: 40% !important;
  }
  @media screen and (max-width: 768px) {
    width: 100% !important  ;
  }
}

.custom-toast-warn {
  color: black !important;
}
.custom-toast-warn svg {
  fill: black !important;
}
.custom-input-error-message .invalid-feedback {
  color: red !important;
  font-weight: 600;
}
.custom-warning-toast {
  background-color: #ffa600d0 !important;
  /* Custom warning background color */
  color: white !important;
  /* Custom text color */
 
}


.multipleFilesClass {
  display: flex;
  justify-content: flex-end;
  // width: 100%;
}

.table-Body {
  border-radius: 0px 0px 10px 10px;
  border: 1px solid #e3e3e3;
}

@media (max-width: 1140px) {
  .custom-1052-styles {
    padding-right: 0.7rem !important;
    padding-left: 0.7rem !important;
  }

  .custom-1052-styles {
    padding-right: 0.7rem !important;
    padding-left: 0.7rem !important;
  }
}
@media (max-width: 867px) {
  .custom-1052-styles {
    padding-right: 0.6rem !important;
    padding-left: 0.6rem !important;
    font-size: 12px;
  }
}

.typeahead-container .rbt-menu {
  max-height: 110px !important;
  overflow-y: auto;
}

.dropdown-menu[data-bs-popper] {
  left: -105px;
}

.dropdown-menu.custom-dropdown-menu[data-bs-popper] {
  left: 0px;
}

.compare-heading {
  font-weight: 600;
  font-size: 36px;
  line-height: 44.29px;
  padding-top: 20px;
}

.compare-div {
  border-radius: 10px;
  border: 1px solid #dee2e6;
}

.compare-btn-txt {
  font-weight: 700px;
  font-size: 18px;
}

.centered-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 110px;
}

.provider-aws {
  background-color: #fff5e6;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 110px;
}

.provider-azure {
  background-color: #edf7fd;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 110px;
}

.provider-google {
  background-color: #ecf1fb;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 110px;
}

.provider-aws-instance {
  background-color: #ff9704 !important;
  color: white !important;
  font-weight: 700;
}

.provider-azure-vm {
  background-color: #46abe7 !important;
  color: white !important;
  font-weight: 700;
  font-size: 16px;
}

.provider-google-vm {
  background-color: #0d61bb !important;
  color: white !important;
  font-weight: 700;
  font-size: 16px;
}
.coloring{
  background-color: white;
   height: 34px; 
}
.custom-card-body {
  padding: 0 !important;
  color: white !important;
  text-align: center;
}
.provider-aws-odd {
  background-color: #fff5e6 !important;
  height: 41px;
}
.provider-azure-odd {
  background-color: #edf7fd !important;
  height: 41px;
}
.provider-google-odd {
  background-color: #ecf1fb !important;
  height: 41px;
}
.provider-aws-even {
  background-color: #fff5e6 !important;
  height: 41px;
}
.provider-azure-even {
  background-color: #edf7fd !important;
  height: 41px;
}
.provider-google-even {
  background-color: #ecf1fb !important;
  height: 41.2px;
}
.provider-head {
  font-weight: 700;
  font-size: 14px;
}

.provider-aws-border {
  border: 2px solid #ffb651 !important ;
  border-radius: none !important;
}

.table-no-border {
  border: none;
}

.report-table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.pagination .page-item.active .page-link {
  background-color: #0d61bb;
  border-color: #0d61bb;
  color: #fff;
}

.recommendation-container {
  background-color: #ffa500; /* Adjust the color to match your design */
  border-radius: 10px; /* Rounded corners */
  padding: 10px;
  margin: 10px;
  display: flex;
  flex-direction: column;
}

.recommendation-header {
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px; /* Spacing between the header and the items */
}

.recommendation-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px; /* Spacing between rows */
}

.recommendation-cell {
  flex: 1;
  padding: 5px; /* Padding inside cells */
  text-align: center;
}

/* If you want the first column to be wider */
.recommendation-cell.instance {
  flex: 2;
}

.recommendation-warning{
  background-color: #ffa600a9 !important;
  font-weight: bold;
    /* Bold font */
  color: black;
}

.compare-instance-table {
  width: 100%; /* Full width for the parent table */
  /* Other styling as needed */
}

/* Ensure the nested table is not wider than its container unless screen size requires it */
.nested-table-responsive .table {
  width: auto;
  height: 176px;
  width: auto;
  min-width: 600px; /* Adjust as needed to fit the content */
}
.compare-table-responsive {
  overflow-y: auto;
}

.aws-btn {

  color: #0D61BB;
  width: 190px;
  text-align: center;
  align-content: center;
  font-weight: 700;
  font-size: 16px;
  
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  padding: 5px;
}
.google-btn {
  background-color: #3f6fd5;
  color: white;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  align-content: center;
  margin-top: -10px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  padding: 5px;
  width: 190px;
}
.azure-btn {
  background-color: #46abe7;
  color: white;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  align-content: center;
  margin-top: -10px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  padding: 5px;
  width: 190px;
}
.row-center {
  display: flex;
  justify-content: center;
  background-color: white;
  text-align: center;
}
.responsive-recomendation-row {
  //background-color: #ff9704 !important;
  color: white !important;
  font-size: 12px;
  font-weight: 400;
}

.recomendation-row {
  //background-color: white !important;
  color: black !important;
  font-size: 12px;
  font-weight: 400;
}

.custom-Table {
  border-collapse: separate !important;
  border-spacing: 0 3px ;
  padding: 10px;
}

// .custom-tablee tbody tr {
//   margin-bottom: 5px; /* This will ensure a gap between rows */
// }
.reco-order:hover{
  
  outline: 1px solid #ff9704 !important;
  border-radius: 5px;
    //   border-top-left-radius: 6px;
    // border-bottom-left-radius: 6px;
}

.reco-order.selected .recomendation-row{
  background-color: #ff9704;
}

.reco-order-azure.selected .recomendation-row-azure{
  background-color: #46abe7 !important;
}
.reco-order-azure:hover {
  outline: 1px solid #46abe7 !important;
  border-radius: 5px;
  // border-color: #ff9704 !important; /* Ensure hover effect with border on all sides */
}
.recomendation-row-azure {
 
  font-size: 12px;
  font-weight: 400;
}

.reco-order-google.selected .recomendation-row-google {
  background-color: #3f6fd5 !important; /* Apply blue background to selected rows */
}
.reco-order-google {
  border: 1px solid transparent; /* Default border to prevent shifting */
  transition: border-color 0.3s; /* Smooth transition for hover effect */
}

.reco-order-google:hover {
  // border: 1px solid #46abe7 !important;
  outline: 1px solid #3f6fd5 !important;
  border-radius: 5px !important;
  // border-color: #ff9704 !important; /* Ensure hover effect with border on all sides */
}
.recomendation-row-google {
  font-size: 12px;
  font-weight: 400;
}

.custom-table {
  max-height: 220px;
  overflow-y: auto;
}
.rounded-left-corners {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.rounded-right-corners {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.aws-rec {
  color: #ff9704 !important;
  text-align: center;
  padding-top: 15px;
}
.azure-rec {
  color: #46abe7 !important;
  text-align: center;
  padding-top: 15px;
}
.google-rec {
  color: #3f6fd5 !important;
  text-align: center;
  padding-top: 15px;
}
.compare-btn {
  //margin-top: 1.8rem;
  margin-left: -10px !important;
  margin-bottom: -20px !important;
}
.compare {
  margin-left: -10px !important;
}
.Instance {
  margin-left: -8px;
}
.compare-typeahead .rbt-input #databaseInstanceSelect {
  width: 120px;
}
.compare-screen-logo {
  margin-top: 15px;
  width: 73px;
  height: 44px !important;
  object-fit: contain;
}
.compare-screen-logo-azure {
  width: 54px;
  height: 54px;
  object-fit: contain;
}
.compare-screen-logo-google {
  
 width: 64px;
  height: 50px;
  object-fit: contain;
}

.logo-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}
.recommendation-instance tbody {
  display: block;
  height: 198px;
  overflow: auto;
}
.recommendation-instance thead,
.recommendation-instance tbody .recommendation-instance tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.show-on-sm {
  display: block;
  margin-top: 38px !important;
}

/* Visible by default, hidden on small screens */
.show-on-lg {
  display: none;
}

@media (min-width: 768px) {
  .show-on-sm {
    display: none;
  }
  .show-on-lg {
    display: block;
    // margin-top: 35px;
  }
}

@media (max-width: 987px) {
  .compare-btn {
    margin-left: 10px !important;
  }
}
@media (max-width: 871px) {
  .compare-region {
    font-size: 14px;
  }
  .compare-region strong {
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  .Instance {
    margin-left: 0px !important;
  }
  .compare-btn {
    margin-left: 10px !important;
  }
  .gcp-region {
    margin-left: 0px !important;
  }
}

@media (max-width: 480px) {
  .Instance {
    margin-left: 0px;
  }
  .compare-btn {
    margin-left: 9px !important;
    padding-right: 1px;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
  }
  .gcp-region {
    margin-left: -2px !important;
  }
  .nested-table-responsive {
    height: 147px !important;
    width: 319px !important;
    overflow: auto;
  }
  .aws-btn {
    background-color: #ff9704;
    color: white;
    width: 120px;
    text-align: center;
    align-content: center;
    font-weight: 400;
    font-size: 12px;
    margin-top: -10px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    padding: 5px;
  }
  .responsive-heading {
    font-size: 14px;
  }
  .compare-heading {
    font-weight: 600;
    font-size: 18px;
    line-height: 44.29px;
    padding-top: 20px;
    margin-left: 30px;
  }

  .region-select-container strong {
    font-size: 13px;
  }
  .compare-regions {
    font-size: 13px;
  }
  .compare-regions strong {
    font-size: 13px;
  }
  .compare-region {
    font-size: 13px;
  }
  .compare-region strong {
    font-size: 13px;
  }
  .form-check-label {
    font-size: 13px;
  }
  .region-select-container .typeahead .rbt-input {
    font-size: 13px;
  }
  // .card-title img{
  //   width: 120px !important;
  // }
  .provider-info .card {
    width: 100% !important;
  }
  .button-spacing {
    padding: 0.15rem !important;
  }
  .svg-inline--fa {
    height: 0.5rem !important;
  }
  .nav-link {
    font-size: 14px !important;
  }
  .steps-container {
    font-size: 14px !important;
  }
}
@media (max-width: 375px) {
  .dropdown-menu {
    margin-left: 47px !important;
  }
  .nested-table-responsive {
    width: 248px !important;
  }
}
.compare-typeahead {
  //position: relative;
  position: absolute; /* This defines the positioning context for the absolute positioning */
}

.typeahead-dropdown-icon {
  position: relative;
  float: right;
  //position: absolute;
  bottom: 19px;
  right:6px; /* Adjust this value to align the icon */
  // top: 50%; /* Centers the icon vertically */
  transform: translateY(-50%); /* Ensures the center alignment is perfect */
  z-index: 3; /* Ensures the icon is above other elements */
  pointer-events: none; /* This ensures the icon doesn't interfere with clicking the input */
}
.gcp-region {
  margin-left: -8px;
  padding-left: 0px;
}
.report-table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.pagination .page-item.active .page-link {
  background-color: #0d61bb;
  border-color: #0d61bb;
  color: #fff;
}
.custom-tooltip-rigion .tooltip-inner {
  color: red !important; /* Change color as needed */
}
.custom-tooltip {
  background-color: blue !important; /* Change to your desired background color */
  color: white; /* Change to your desired text color */
}
.tooltip-inner {
  background-color: #fff !important;
  color: black !important;
}
.tooltip-arrow::before {
  background-color: #fff !important;
  border-bottom-color: #fff !important;
}
.tooltip {
  background-color: #fff !important;
  color: #fff;
}
.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #fff !important;
}
.option-gray {
  color: #909090 !important;
  font-size: 12px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.add-instance {
  width: 166px;
  border-radius: 5px;
  height: 35px;
  font-weight: 600;
  font-size: 16px;

  background-color: #0d61bb;
  border: none;
  float: right;
  color: white;
}

.error-tab {
  color: 1px solid red !important;
}
// input.is-invalid {
//   border: 2px solid red !important;
// }
.cloud-card {
  width: 250px;
  height: 80px;
  border-radius: 6px;
  background-color: #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.loadtemplet-heading {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
.architecture-card {
  width: 226px;
  height: 120px;
  border-radius: 6px;
  background-color: #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.architecture-heading {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000;
}
.package-card {
  width: 120px;
  height: 100px;
  border-radius: 6px;
  border: 1px;
  background-color: #f3f3f3;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  flex-direction: column;
}
.package-h {
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  margin: 0;
  text-align: center;
}
.package-subheading {
  font-weight: 700;
  font-size: 12px;
  margin: 0;
  line-height: 20px;
  text-align: center;
}
.load-t-btn {
  float: right;
  //width: 550px;
  margin-top: 20px;
}
.archi {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
}
.manual-btn {
  width: max-content;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #0d61bb;
  padding: 7px 30px 7px 30px;
  gap: 10px;
  background-color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  line-height: 26.24px;
  color: #0d61bb;
}
.load-btn {
  width: 193px;
  height: 40px;
  border-radius: 5px;
  background-color: #0d61bb;
  // Padding:7px 30px 7px 30px;
  gap: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 26.24px;
  color: #ffffff;
  border: none;
}
.load-heading {
  font-weight: 600;
  font-size: 20px;
  line-height: 24.2px;
}
.load-subheading {
  font-weight: 400;
  font-size: 16px;
  line-height: 19.36px;
}
// .custom-typeahead.invalid {
//   border: 1px solid red;
//   background-image: none !important; /* This might be necessary to remove the icon */
// }
.form-control.is-invalid {
  background-image: none !important;
  padding-right: 0px !important;
}
.control.is-invalid {
  padding-right: 0px !important;
}

.TimesCircle {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  color: gray;
}
.uploadFile {
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid #e6e5e5;
  border-radius: 5px;
  margin: 5px;
  padding: 5px;
  display: "flex";
}
.iconTime {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  color: gray;
}
.region-select .rbt-input {
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected-vendor + label {
  background-color: #ccddf0;
  // border:2px solid #aec9e5;
  //color: white;
  box-shadow: 6px 4px 6px #a8bbcf;
  border-radius: 5px;
  padding: 10px;
}
//about
.about_top {
  width: 100%;
  // max-width: 1920px;
  height: 343px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(13, 97, 187, 0.97) 0%,
    #054d99 100%
  );
  position: relative;
}

.about_top_inner_content {
  width: 80%;
  max-width: 915px;
  height: auto;
  justify-content: center;
  top: 38%; /* Adjust vertically */
  left: 50%; /* Adjust horizontally */
  transform: translateX(-50%);
  position: absolute;
  font-weight: 700;
  font-size: 30px;
  // line-height:36.31px ;
  text-align: center;
  color: white;
}

// .main_cloud_equate {
//   display: flex;
//   justify-content: space-between;
//   width: 100%;
// }
// .cloudequate_title {
//   width: 411px;
//   height: 44px;
//   margin-top: 114px;
//   margin-left: 311px;
//   display: flex;
//   justify-content: center;
//   align-content: center;
//   font-weight: 700px;
//   font-size: 36px;
//   align-items: center;
//   color: #054d99;
//   line-height: 30px;
// }
// .cloudequate_content {
//   width: 734px;
//   height: 240px;
//   margin-top: 30px;
//   margin-left: 310px;
//   line-height: 30px;
//   font-weight: 400px;
//   font-size: 18px;
// }
// .get_started_btn {
//   width: 262px;
//   height: 58px;
//   margin-top: 50px;
//   margin-left: 310px;
//   border-radius: 4px;
//   border: 2px solid #0d61bb;
//   padding: 17px 35px 17px 35px;
//   background-color: #0d61bb;
//   gap: 10px;
//   color: white;
// }

// .about_cloudequate_img {
//   width: 488px;
//   height: 541.46px;
//   margin-top: 59px;
//   margin-left: 25px;
// }
.main_cloud_equate {
  display: flex;
  // justify-content: space-around;
  // justify-content: center;
  justify-content: space-evenly;
  width: 100%;
}

.cloudequate_title {
  // width: 50%;
  // max-width: 411px;

  // width: 411px;
  width: 493px;
  height: 44px;

  margin-top: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 36px;
  color: #054d99;
  line-height: 43.57px;
}

.cloudequate_content {
  // width: 100%;
  // max-width: 734px;
  width: 734px;
  height: 240px;
  margin-top: 4%;
  margin-left: 5%;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
}

.get_started_btn {
  // width: 50%;
  // max-width: 262px;
  text-decoration: none;
  text-align: center;
  padding-top: 15px;
  width: 262px;
  height: 58px;
  margin-top: 9% !important;
  margin-left: 5%;
  border-radius: 4px;
  border: 2px solid #0d61bb;
  // padding: 17px, 35px, 17px, 35px;
  background-color: #0d61bb;
  display: block;
  gap: 10px;
  color: white;
}

.about_cloudequate_img {
  width: 40%;
  max-width: 488px;
  height: 541.5px;
  margin-top: 5%;
  // margin-left: 5%;
  margin-left: -25%;
}

.main_wrapper_sap {
  width: 100%;
  // max-width: 1920px;
  height: 571px;
  margin-top: 82px;
  margin-left: auto;
  margin-right: auto;
  background-color: #f1f1f1 !important;
  display: flex;
  // justify-content: space-around;
  justify-content: center;
  gap: 40px;
}

.about_sap_title {
  width: 473px;
  height: 88px;
  margin-top: 82px;
  margin-left: 0px;
  margin-right: auto;
  font-size: 36px;
  font-weight: 700;
  line-height: 43.57px;
  color: #054d99;
}

.about_sap_content {
  width: 687px;
  height: 240px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
}

.about_why_cloudequate_title {
  width: 90%;
  // max-width: 393px;
  max-width: 401px;
  height: 44px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 700;
  font-size: 36px;
  line-height: 43.57px;
  text-align: center;
  color: #054d99;
}
.cardsPadding {
  padding: 0 80px;
}
.about_main {
  position: relative;
}

.main_looking_ahead {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 622px;
  background-color: #dff2fe;
}

.looking_ahead_title {
  max-width: 302px;
  width: 100%;
  height: 44px;
  font-weight: 700;
  font-size: 36px;
  color: #054d99;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 10px;
}

.looking_ahead_content {
  width: 80%;
  max-width: 958px;
  height: auto;
  text-align: center;
  font-size: 18px;
  line-height: 27.99px;
  font-weight: 400;
  margin-top: 24px;
}

.looking_ahead_btn {
  width: 100%;
  max-width: 262px;
  // height: auto;
  text-decoration: none;
  height: 58px;
  border: 2px solid #0d61bb;
  border-radius: 4px;
  padding: 17px 35px 17px 35px;
  gap: 10px;
  background-color: #0d61bb;
  color: white;
  // margin-top: 0.7vw;
}

.about_main_email {
  align-content: center;
  width: 65%;
  height: 358px;
  border-radius: 10px;
  background-color: #0d61bb;
  overflow: hidden;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 45%;
  left: 50%;
  box-shadow: 0px 6px 4px 0px #00000000;
}

.about_email_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.about_email_left {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about_email_title {
  width: 100%;
  max-width: 445px;
  height: 70px;
  font-size: 24px;
  // line-height: 1.5;
  line-height: 34.81px;
  color: #ffffff;
  margin-top: 16px;
  margin-left: 100px;
}

.about_email_description {
  width: 100%;
  max-width: 483px;
  height: 104px;
  font-size: 18px;
  font-weight: 400;
  // line-height: 1.5;
  line-height: 26.1px;
  color: #ffffff;
  margin-top: 10px;
  margin-left: 140px;
}

.about_email_img {
  width: 70%;
  max-width: 375px;
  height: auto;
  margin-top: 2vw;
  margin-left: 80px;
}

.form-email-about{
  width: 323px ;
  height: 47px ;
}
.form_email {
  width: 70%;
  max-width: 480px;
  height: 48px;
  margin-top: 2vw;
  display: flex;
  flex-direction: row;
}
.inputEmail {
  width: 323px; 
  height: 47px;
}

.email_btn {
  width: 30%;
  max-width: 142px;
  height: auto;
  color: #ffffff;
  gap: 2vw;
}

.email-size{
   height: 47px; 
   width: 323px;
}
@media screen and (max-width: "2560px") {
  .sap_img_ {
    height: 444px;
    margin-top: 60px;
  }
  .looking_ahead_title {
  margin-top: 70px;
  }
  .form_wrapper {
    margin-left: 68px;
  }
}
@media screen and (max-width: "1900px") {
  // .looking_ahead_title {
  //   max-width: 400px;
  //   width: 100%;
  // }
  .looking_ahead_btn {
    max-width: 300px;
  }
  .sap_img_ {
    height: 444px;
    margin-top: 73px;
  }
  .form_wrapper {
    margin-left: 39px;
  }
}
@media screen and (max-width: "1760px") {
  .about_email_title {
    // font-size: 20px;
  }
  .about_email_description {
    // font-size: 15px;
  }
  .sap_img_ {
    height: 444px;
    margin-top: 73px;
  }
  .form_email {
    margin-top: 30px;
    margin-left: -31px;
  }
  .inputEmail {
    width: 290px;
  }
  .email_btn {
    width: 88px;
    margin-left: 10px;
  }
  .form_wrapper {
    margin-left: 41px;
  }
}
@media screen and (max-width: "1536px") {
  .about_top_inner_content {
    width: 80%;
  }
  .about_img_ {
    // height: 430px;
  }

  .sap_img_ {
    height: 444px;
    margin-top: 73px;
  }
  .looking_ahead_title {
    margin-top: 24px;
  }

  /*...*/
  .about_email_title {
    // font-size: 18px;
  }
  .about_email_description {
    // font-size: 13px;
  }
  
  .form_wrapper {
    margin-left: 18px;
  }
  .form_email {
    margin-top: 22px;
    margin-left: -40px;
  }
  .inputEmail {
    width: 260px;
  }
  .email_btn {
    width: 80px;
    margin-left: 3px;
  }
  .about_main_email {
    height: 330px;
    top: 40%;
  }
}
// @media screen and (max-width: "1450px") {
//   .about_email_description {
//     margin-left: 111px;
//   }
// }

@media screen and (max-width: "1447px") {
  .about_top_inner_content {
    width: 820px;
  }
  .about_img_ {
    height: 500px;
    margin-right: -55px;
  }
  .about_email_description {
    margin-left: 111px;
  }
  .form_wrapper {
    margin-left: 7px;
  }
}
@media screen and (max-width: "1387px") {
  .about_img_ {
    margin-top: 15px;
    height: 452px;
    margin-right: -65px;
  }
  .about_top_inner_content {
    width: 815px;
  }
  .about_email_description {
    margin-left: 106px;
  }
  .form_email {
    margin-left: -107px;
  }
  .form_wrapper {
    margin-left: 41px;
  }
}
@media screen and (max-width: "1280px") {
  // .about_top_inner_content {
  //   // width: 65%;
  // }
  .about_top_inner_content {
    width: 912px;
  }
  .about_cloud_equate {
    margin-left: 5%;
  }
  .about_img_ {
    height: 445px;
    margin-right: 10px;
  }
  .cloudequate_title {
    font-size: 36px;
  }
  .cloudequate_content {
    width: 83%;
    font-size: 17px;
  }
  .get_started_btn {
    font-size: 14px;
    margin-top: 35px;
    width: 247px;
    height: 52px;
  }
  .main_wrapper_sap {
    gap: 0px;
  }
  .sap_img_ {
    // height: 300px;
    height: 400px;
    margin-top: 100px;
    margin-left: 94px;
  }
  .about_sap_title {
    width: 77%;
    font-size: 36px;
    margin-left: 66px;
    margin-top: 115px;
  }
  .about_sap_content {
    width: 80%;
    font-size: 17px;
    margin-left: 55px;
  }

  .about_why_cloudequate_title {
    font-size: 36px;
  }
  .cardsPadding {
    padding: 0px;
  }
  .aboutCard {
    font-size: 17px;
  }
  .looking_ahead_content {
    max-width: 804px;
  }

  /*...*/
  .about_email_title {
    font-size: 18px;
    font-weight: 600;
    width: 315px;
    line-height: 29px;
    margin-left: 43px;
  }
  .about_email_description {
    font-size: 14px;
    width: 315px;
    line-height: 20px;
    margin-left: 43px;
    margin-top: 2px;
  }
  .form_wrapper {
    margin-left: 57px;
  }
  .form_email {
    margin-top: 0.5vw;
  }
  .inputEmail {
    width: 263px;
  }
  .email_btn {
    width: 80px;
   
  }
  .about_main_email {
    height: 330px;
    top: 40%;
  }
}

@media screen and (max-width: "1160px") {
  .cloudequate_content {
    width: 80%;
    font-size: 17px;
  }
  .get_started_btn {
    font-size: small;
    margin-top: 124px !important;
  }
  .form_wrapper {
    margin-left: 46px;
  }
}

@media screen and (max-width: "1150px") {
  .email-width{
    margin-left: -20px !important;
  }
  .cloudequate_title {
    font-size: 30px;
    margin-left: -40px;
  }
  .sap_img_ {
    // height: 360px;
    margin-top: 100px;
    margin-left: 48px;
  }
  .about_sap_title {
    width: 77%;
    margin-left: 68px;
    margin-top: 74px;
    font-size: 30px;
  }
  .about_sap_content {
    width: 80%;
    margin-left: 68px;
  }
  .about_why_cloudequate_title {
    font-size: 30px;
  }
  .form_wrapper {
    margin-left: 39px;
  }
  
  .form-email-about{
    width: 203px !important;
  }
 
  .email_btn{
    margin-left: 5px;
  }
}

@media screen and (max-width: "1024px") {
  .about_top_inner_content {
    width: 80%;
    font-size: 26px;
  }
  .about_img_ {
    height: 375px;
    margin-top: 30px;
  }
 
  .cloudequate_content {
    width: 84%;
    line-height: 27px;
  }
  .get_started_btn {
    font-size: small;
    margin-top: 95px;
    // margin-top: 2%;
  }
  .sap_img_ {
    height: 370px;
    margin-top: 120px;
  }
  .about_sap_title {
    width: 77%;
    margin-left: 50px;
  }
  .about_sap_content {
    width: 85%;
    margin-left: 50px;
  }
  /*...*/
  .about_email_title {
    font-size: 18px;
    width: 315px;
    margin-left: 50px;
    line-height: 26px;
  }
  .about_email_description {
    font-size: 14px;
    width: 315px;
    margin-left: 50px;
    margin-top: 2px;
  }
  .form_wrapper {
    margin-left: 25px;
  }
  .form_email {
    margin-top: 7px;
    margin-left: -94px;
  }
  .inputEmail {
    width: 263px;
    margin-left: -20px;
  }
  .email_btn {
    width: 80px;
    // margin-left: -86px;
  }
  .about_main_email {
    height: 330px;
    top: 40%;
  }
}
@media screen and (max-width: "928px") {
  .about_img_ {
    height: 300px;
  }
  .cloudequate_title {
    // margin-left: 30px;
  }
  .cloudequate_content {
    width: 82%;
    font-size: 14px;
    line-height: 23px;
  }
  .get_started_btn {
    // font-size: 10px;
    margin-top: 58px !important;
    line-height: 26px;
    font-size: 12px;
  }
  .sap_img_ {
    height: 325px;
    // margin-top: 100px;
  }

  .about_sap_title {
    margin-left: 60px;
    margin-top: 46px;
  }
  .about_sap_content {
    width: 73%;
    font-size: 14px;
    margin-top: 56px;
    margin-left: 60px;
    line-height: 26px;
  }
  .aboutCard {
    font-size: 14px;
  }
  .main_looking_ahead {
    height: 500px;
  }
  .looking_ahead_title {
    margin-top: 16px;
  }
  .looking_ahead_content {
    font-size: 14px;
    line-height: 25px;
    margin-top: 20px;
  }
  .looking_ahead_btn {
    padding: 6px 24px 6px 24px;
    font-size: 12px;
    margin-top: -11px;
    height: 47px;
  }
  .about_email_title {
    font-size: 16px;
    width: 272px;
    margin-left: 68px;
  }
  .about_email_description {
    font-size: 12px;
    width: 272px;
    margin-left: 64px;
    margin-top: 2px;
  }
  .form_wrapper {
    margin-left: 47px;
  }
  .form_email {
    margin-top: 10px;
    // margin-left: -59px;
  }
  .inputEmail {
    height: auto;
    width: 241px;
    margin-left: -20px;
    margin-top: -6px;
  }
  .form-email-about{
    // width: 150px !important;
    margin-top: -8px;
    height: 41px !important;
  }
  .email_btn {
    width: 78px;
    margin-left: 2px;
    height: 41px;
    width: 78px;
    margin-top: -8px;
  }
  .about_main_email {
    height: 279px;
    top: 38%;
  }
  .about_email_img {
    margin-left: 44px;
  }
}

@media screen and (max-width: "768px") {
  .about_top_inner_content {
    font-size: 22px;
  }
  .main_cloud_equate {
    flex-direction: column;
    align-items: center;
  }
.form_wrapper{
  margin-left: 60px !important;
}
  .about_img_ {
    margin-left: 50%;
  }
  .about_cloud_equate {
    margin-left: 0px;
  }
  .cloudequate_title,
  .cloudequate_content,
  .get_started_btn {
    // width: 90%;
    max-width: none;
    margin: 5% auto;
  }

  .cloudequate_title {
    font-size: 26px;
  }

  .cloudequate_content {
    /* Adjust font size responsively for smaller screens */
  }

  .get_started_btn {
    margin-top: -18px !important;
    padding-top: 11px;
  }
  .about_cloudequate_img {
    display: flex;
    // margin-left: auto;
    margin-right: auto;
    height: 400px;
  }
  .sap_img_ {
    height: 343px;
    width: 298px;
    margin-top: 24px;
  }

  .main_wrapper_sap {
    flex-direction: column;
    height: auto;
    // padding: 20px;
  }
  .about_sap_img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    height: 444px;
    width: 453px;
  }

  .about_sap_title {
    max-width: 100%;
    margin-top: -40px;
    font-size: 26px;
    line-height: 36px;
    margin-left: 10%;
  }

  .about_sap_content {
    max-width: 100%;
    margin-top: 1%;
    width: 90%;
    margin-left: 8%;
    line-height: 25px;
  }
  .about_why_cloudequate_title {
    font-size: 26px;
    margin-top: 20px;
  }
  .main_looking_ahead {
    width: 100%;
    margin-top: 10vw;
  }

  .looking_ahead_title {
    font-size: 26px;
  }

  .looking_ahead_content {
    // font-size: 16px;
    margin-top: 16px;
    line-height: 22px;
  }

  .looking_ahead_btn {
    padding: 8px 14px;
    margin-top: 10px;
  }
  /*...*/
  .about_email_title {
    // font-size: 16px;
    // width: 315px;
    // margin-left: -7px;
    margin-left: 215px;
    // margin-left: 39px;
  }
  .about_email_description {
    // font-size: 14px;
    // width: 315px;
    // margin-left: -7px;
    // margin-top: -10px;
    margin-left: 215px;
  }

  .form_email {
    margin-top: 0px;
    margin-left: -10px;
  }
 
  .inputEmail {
    width: 245px;
    margin-left: 13px;
  }
  .email_btn {
    width: 80px;
    margin-left: 9px;
  }
  .about_main_email {
    height: 240px;
    top: 30%;
  }
  .about_email_img {
    display: none;
  }
}
@media screen and (max-width: "767px") {
  .about_email_title {
    font-size: 16px;
    width: 315px;
    margin-left: -7px;
    // margin-left: 215px;
    // margin-left: 39px;
  }
  .about_email_description {
    font-size: 14px;
    width: 315px;
    margin-left: -7px;
    margin-top: -10px;
    // margin-left: 215px;
  }
  .form_email {
    margin-top: 2px;
    margin-left: -90px;
  }
}

@media screen and (max-width: "640px") {
  .get_started_btn {
    margin-top: 55px !important;
  }

  .cloudequate_content {
    line-height: 25px;
  }
  .looking_ahead_content {
    line-height: 22px;
  }
  .title-ahead {
    margin-top: 16px;
  }
  .about_top_inner_content {
    font-size: 23px;
    margin-top: -24px;
  }

  .inputEmail {
    width: 205px;
    margin-left: 10px;
    margin-top: -27px;
  }

  .email_btn {
    width: 66px;
    margin-left: 2px;
    font-size: 12px;
    margin-top: -27px;
    height: 36px;
  }
  .about_main_email {
    height: 205px;
  }
  .about_email_title {
    font-size: 15px;
    margin-left: 15px;
    line-height: 24px;
  }
  .about_email_description {
    font-size: 12px;
    margin-left: 20px;
    line-height: 18px;
  }
  .form-email-about{
    margin-top: -27px !important;
  height: 35px !important;
  width: 188px !important;
  }

  .form_wrapper{
    margin-left: 44px !important;
  }
}
@media screen and (max-width: "524px") {
  .cloudequate_content {
    line-height: 23px;
    font-size: 13px;
  }
  .about_sap_content {
    line-height: 22px;
    font-size: 13px;
  }
  .email_btn {
    width: 64px;
    margin-left: -145px;
    font-size: 11px;
    margin-top: -36px;
    height: 33px;
  }
  .about_main_email {
    height: 177px;
    margin-top: 26px;
  }
  .about_email_title {
    font-size: 13px;
    width: auto;
    line-height: 22px;
  }
  .about_email_description {
    font-size: 11px;
    margin-left: 20px;
    margin-top: -20px;
    line-height: 17px;
  }
  .inputEmail {
    width: 202px;
    margin-left: -19px;
    margin-top: -33px;
    height: 29px;
  }
  .sap_img_ {
    height: 333px;
    width: 288px;
    margin-top: 3%;
  }
}
@media screen and (max-width: "495px") {
  .cloudequate_title {
    font-size: 20px;
  }
  .about_top_inner_content {
    font-size: 18px;
  }

  .about_sap_title {
    max-width: 100%;
    // margin-top: 24px;
    margin-top: -40px;
    font-size: 20px;
    line-height: 36px;
    margin-left: 10%;
  }
  .email_btn {
    width: 64px;
    margin-left: -159px;
    font-size: 11px;
    margin-top: -35px;
    height: 32px;
  }
  .about_main_email {
    height: 177px;
    margin-top: 26px;
  }
  .about_email_title {
    font-size: 13px;
    width: auto;
  }
  .about_email_description {
    font-size: 10px;
    margin-left: 20px;
    margin-top: -20px;
    width: fit-content;
  }
  .about_why_cloudequate_title {
    font-size: 20px;
  }
  .looking_ahead_title {
    font-size: 20px;
  }
  .form_email {
    margin-top: -3px;
  }
  .inputEmail {
    width: 189px;
    margin-left: -19px;
    margin-top: -33px;
    height: 30px;
  }
}
.region-select.is-invalid .rbt-input {
  border-color: red;
}
.invalid-typeahead-container {
  border-color: red !important;
}
.invalid-typeahead-container .rbt-input {
  border-color: red !important;
}
.region-select-container .dropdown-fix {
  top: 33%;
}
.error-message {
  width: 50%;
  border: 1px solid #cc0000;
  background-color: #ffe6e6;
  color: #cc0000;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
}

.error-message ul {
  margin: 0;
  padding: 0;
  // list-style-type: none;
}

.error-message li {
  margin-left: 20px;
}

.error-icon {
  color: #cc0000;
  margin-right: 5px;
}
.error-warning {
  width: 50%;
  border: 1px solid #f7c600;
  background-color: #f7f4d6;
  color: #f7c600;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
}

.error-warning ul {
  margin: 0;
  padding: 0;
  // list-style-type: none;
}

.error-warning li {
  margin-left: 20px;
}

.error-icon-warning {
  color: #f7c600;
  margin-right: 5px;
}

@media screen and (max-width: "435px") {
  .get_started_btn {
    margin-top: 70px !important;
    width: 50%;
  }
  .looking_ahead_btn {
    margin-top: 14px;
  }
  .inputEmail {
    width: 165px;
  }
  .email_btn {
    width: 62px;
    margin-left: -177px;
  }
}
@media screen and (max-width: "414px") {
  .get_started_btn {
    margin-top: -42px !important;
  }
  // .cloudequate_content {
  //   font-size: 10px;
  //   line-height: 17px;
  // }
  .cloudequate_content {
    font-size: 10px;
    line-height: 17px;
    width: 70%;
  }
  .about_sap_content {
    font-size: 10px;
    line-height: 17px;
  }
  .form_email {
    margin-left: -85px;
  }
  .about_email_title {
    line-height: 16px;
  }
  .about_email_description {
    line-height: 14px;
  }
}
@media screen and (max-width: "400px") {
  .inputEmail {
    width: 150px;
  }

  .email_btn {
    width: 61px;
    margin-left: -196px;
  }
  .cloudequate_content {
    width: 65%;
  }
}

@media screen and (max-width: "375px") {
  .looking_ahead_btn {
    margin-top: 0px;
  }
  .about_why_cloudequate_title {
    font-size: 18px;
  }
  .cloudequate_content {
    width: 60%;
    line-height: 14px;
  }
  .about_email_title {
    margin-left: 0px;
    font-size: 9px;
    width: 76%;
  }
  .about_email_description {
    font-size: 8px;
    margin-left: 16px;
    margin-top: -28px;
    width: 81%;
  }
  .form_email {
    margin-left: -69px;
  }
  .inputEmail {
    width: 130px;
  }

  .email_btn {
    width: 50px;
    margin-left: -211px;
    font-size: 8px;
    height: 28px;
    margin-top: -31px;
  }
}

.custom-radio-button {
  Width: 80px;
  height: 80px;
  background-color: #f7fafc;
  border: 2px solid #d8dfe8;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  transition: border-color 0.3s, background-color 0.3s;
}

.custom-radio-button:hover {
  border-color: #ff9704;
  background-color: #fff5e6;
}

.selected-vendor {
  border-color: #ff9704;
  background-color: #fff5e6;
}

.vendor-icon-wrapper {
  display: flex;
  align-items: center;
  object-fit: cover;
}

.select-vendor-part {
  display: flex;
  flex-direction: column;
}

.vendor-icon-container {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  float: right;
}


// .vendor-icon-aws {
//   max-width: 90px;
//   max-height: 70px;
// }

// .vendor-icon-azure {
//   max-width: 70px;
//   max-height: 70px;
// }

// .vendor-icon-gcp {
//   max-width: 70px;
//   max-height: 70px;
// }

/* Custom styles for the multiselect component */
.multi-select .p-multiselect-label:not(.p-placeholder) {
  // display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
}

.multi-select .p-multiselect-token {
  background-color: #c8dbef;
  color: #2470c2;
  // background-color: #007bff; /* Example blue background */
  // color: white;
  border-radius: 16px; /* Rounded edges for tokens */
  font-size: 10px; /* Example font size */
  padding: 4px 6px;
  margin-right: 2px;
}
.multi-select .p-multiselect-token .p-multiselect-token-icon::before,
.multi-select .p-multiselect-token .p-multiselect-token-icon::after {
  color: white; /* Adjust close icon color */
}

.multi-select .p-multiselect-token-icon {
  color: white; /* Adjust close icon color inside token */
  margin-left: 4px;
  // height: 11px;
  // border: none !important;
  background-color: #0d61bb;
  border-radius: 50%;
}

.multi-select .p-multiselect-trigger {
  background: transparent;
  border: none;
}

.multi-select .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  padding: 8px 10px; /* Padding for dropdown items */
  border-radius: 16px; 
  
}

/* Hide the checkbox inside the dropdown */
.multi-select .p-checkbox .p-checkbox-box {
  display: none;
}

/* Style the dropdown panel */
.multi-select .p-multiselect-panel {
  border-radius: 20px; /* Rounded corners for the dropdown panel */
}

/* Additional styling for overall component */
.multi-select {
  width: 220px;
  border: 1px solid #ccc;
  border-radius: 5px; /* Rounded corners for the outer component */
  padding: 5px 10px;
  background-color: #f7fafc;
}

.multi-select .p-multiselect-label.p-placeholder {
  color: #909090 !important;
  font-size: 12px !important;
  padding-top: 4px;
  padding-bottom: 4px;
}

/* Ensure the overall input size is consistent */
.multi-select .p-multiselect {
  font-size: 0.875rem; /* Match the size with Region dropdown */
}
.p-multiselect-header{
  display: none;
}
.p-multiselect-items{
  background-color: white;/* Rounded corners for items */
  border:1px solid #dee2e6;
  border-radius: 0.375rem;
  border-width: 1px;
}
.p-multiselect-item{
  padding-left:1rem;
  padding-right: 1rem;
  margin-left:-2rem;
  font-family: sans-serif;
  font-size: 14px;
  width: 260px;
}

.p-multiselect-item:hover {
  background-color: #f8f9fa;
}
.p-checkbox-input{
  display: none ;
}
.p-multiselect-item.p-highlight {
  background-color: #c2e0ff;
  font-weight: bold;
}

// .p-checkbox-input{
//   display: none;
// }
.multi-select .p-multiselect-label {
  text-overflow: ellipsis !important;  /* This will allow text to wrap and avoid expanding the container */
}
.multi-select .p-multiselect-panel {
  width: auto;  /* Ensures that the dropdown width is not affected by the input width */
}
.p-checkbox-box{
  display: none;
}

.compare-main-container{
  background-color: white;
  border: 1px solid #DEE2E6;
  border-radius: 6px;
}
.compare-recommendation-table-head{
  font-size: 18px;
  font-weight: 700 
}
.compare-conter-head{
  padding-left: 21px !important ;
}
.recommendation-tab-data{
  height: 250px;
}
.fixTableHead {
  overflow-y: auto;
  height: 15em;
  margin: 10px;
}
 .fixTableHead thead th {
  position: sticky;
  top: 0;
}
.flex-container {
  display: flex;
  flex-direction: row;
  font-size: 16px;
}

.flex-item-left {
  padding: 2px;
  // margin-left:10px;
  margin-bottom: 10px;
  flex: 50%;
}

.flex-item-right {
  padding: 2px;
  flex: 50%;
  margin-bottom: 10px;
  // margin-left:10px;
}
.options-container-compare {
  width: 100%;
}
.recommendation-table-header{
  background-color: #ecf1fb !important;
  font-size: 14px;
  font-weight: 400;
}
.recommendation-table-header-azure{
  background-color: #edf7fd !important;
  font-size: 14px;
  font-weight: 400;
}
.recommendation-table-header-aws{
  // background-color: #f7eedf !important;
  background-color: #fff5e6 !important;
  font-size: 14px;
  font-weight: 400;
}
.recommendation-table-header,
.recommendation-table-header-azure,
.recommendation-table-header-aws {
  border: none !important;
}

.recommendation-instance-align{
  text-align: center !important;
}
.compare-head-weight{
  font-weight: 400 !important;
}

th, td {
  font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

/* Responsive layout - makes a one column-layout instead of two-column layout */
@media (max-width:871px) {
  .flex-container {
    font-size: 14px;
  }
}
@media (max-width: 800px) {
  .flex-container {
    flex-direction: column;
    font-size: 14px;
  }
  .flex-item-left {
    padding: 0px;
    // margin-left:10px;
    margin-bottom: 0px;
    flex: 50%;
  }

  .flex-item-right {
    padding: 0px;
    flex: 50%;
    margin-bottom: 0px;
  }
}